<template>
  <div>
    <!-- <div class="flex flex-wrap">
      <button @click="applyBold" class="button">Bold</button>
      <button @click="applyItalic" class="button">Italic</button>
      <button @click="applyHeading" class="button">Heading</button>
      <button @click="applyUl" class="button">ul</button>
      <button @click="applyOl" class="button">ol</button>
      <button @click="undo" class="button">undo</button>
      <button @click="redo" class="button">redo</button>
    </div> -->
    <div
      @input="onInput"
      v-html="innerValue"
      contenteditable="true"
      class="wysiwyg-output outline-none border-2 p-4 rounded-lg border-gray-300 focus:border-green-300"
    />
  </div>
</template>

<script setup lang="ts">
import { Marked } from "@ts-stack/markdown";
//@ts-ignore
import TurndownService from "turndown";
import { ref, onMounted, watch } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});

const $emit = defineEmits(["edit-value"]);
const innerValue = ref();

const onInput = (event: any) => {
  const turndown = new TurndownService({
    emDelimiter: "_",
    linkStyle: "inlined",
    headingStyle: "atx",
  });
  $emit("edit-value", turndown.turndown(event.target.innerHTML));
};
const applyBold = () => {
  document.execCommand("bold");
};
const applyItalic = () => {
  document.execCommand("italic");
};
const applyHeading = () => {
  document.execCommand("formatBlock", false, "<h1>");
};
const applyUl = () => {
  document.execCommand("insertUnorderedList");
};
const applyOl = () => {
  document.execCommand("insertOrderedList");
};
const undo = () => {
  document.execCommand("undo");
};
const redo = () => {
  document.execCommand("redo");
};


onMounted(() => {
  document.execCommand("defaultParagraphSeparator", false, "p");
  innerValue.value = Marked.parse(props.value) || '<p><br></p>'
});
</script>
<style>
.wysiwyg-output h1 {
  @apply text-2xl;
  @apply font-bold;
  @apply pb-4;
}
.wysiwyg-output p {
  @apply pb-4;
}
.wysiwyg-output p {
  @apply pb-4;
}
.wysiwyg-output ul {
  @apply ml-6;
  @apply list-disc;
}
.wysiwyg-output ol {
  @apply ml-6;
  @apply list-decimal;
}
</style>
