import * as _ from "lodash";

import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { LensItemsGroup } from "@/index";
// @ts-ignore
import { createToaster } from "@meforma/vue-toaster";
import { defineStore } from "pinia";
import entityStateManager from "@use/entity";
import { storeToRefs } from "pinia";
import { useConfigurationStore } from "@/@modules/configuration/store/configurationStore";
import { useLensGroupStore } from "./lensGroupStore";

const lensRoutes =[
  "admin-lens-bursts-csv-id" ,
  "admin-lens-bursts-id" ,
  "admin-lens-bursts-modular-id" ,
  "admin-lens-generic-bursts-csv-id" ,
  "admin-lens-generic-bursts-id" ,
  "admin-lens-generic-bursts-report-id" ,
  "admin-lens-generic-bursts-custom-report-id" ,
  "admin-lens-generic-statistics-csv-id" ,
  "admin-lens-generic-statistics-id" ,
  "admin-lens-options-csv-id",
  "admin-lens-options-stats-chart-id" ,
  "admin-lens-options-stats-variable-id" ,
  "admin-lens-options-stats-variable-id" ,
  "admin-lens-settings-recipient-id" ,
  "admin-lens-statistics-csv-id" ,
  "admin-lens-statistics-id"
]

export const useLensStore = defineStore(
  "lens",
  () => {
    const lensGroupStore = useLensGroupStore();
    const router = useRouter();
    const route = useRoute();
    const manager = entityStateManager("lens_id");
    const config = useConfigurationStore();

    const { lensGroups } = storeToRefs(config);

    const selectedLensItems = ref<LensItemsGroup | null>();

    const lens = ref();
    const dataEntities = ref(manager.initialState);

    const clear = () => {
      selectedLensItems.value = undefined;
    };

    /**
     * Définit les éléments de lens sélectionnés.
     *
     * @param {Object} params - Les paramètres de la fonction.
     * @param {string|string[]} params.cosmosId - L'identifiant ou les identifiants Cosmos des éléments de lens.
     * @param {string|string[]} params.locationId - L'identifiant ou les identifiants de l'emplacement.
     */
    const setSelectedLensItems = ({ cosmosId, locationId }: { cosmosId: string | string[]; locationId: string | string[] }) => {
      try {
        lensGroupStore?.setSelectedLensGroups(locationId as string);
        selectedLensItems.value = manager.getEntityById(dataEntities.value, cosmosId as string);
      } catch (error) {
        router.push("/admin");
      }
    };

    /**
     * Définit toutes les lens.
     *
     * Cette fonction parcourt tous les groupes de lens disponibles dans lensGroupStore
     * et définit toutes les lens de chaque groupe en utilisant la méthode setAllEntities du gestionnaire.
     * Les entités résultantes sont ensuite fusionnées avec les entités existantes dans dataEntities.value.
     * Les identifiants des nouvelles entités sont également ajoutés à la liste des identifiants existants.
     * Enfin, les entités sont converties en tableau et assignées à la variable lens.
     */
    const setAllLens = () => {
      lensGroupStore?.lensGroups?.map((group) => {
        const { ids, ...val } = manager.setAllEntities(group.lensGroup_data.lens_items);
        // @ts-ignore
        dataEntities.value = {
          entities: { ...dataEntities.value.entities, ...val.entities },
          ids: _.uniq([...dataEntities.value.ids, ...ids]),
        };
      });
      lens.value = manager.entitiesToArray(dataEntities.value);
    };

    /**
     * Récupère tous les groupes de lens et met à jour les entités de données.
     *
     * @returns Une Promise qui se résout lorsque toutes les lens sont récupérées et les entités de données sont mises à jour.
     */
    const setAllOtherLens = async () => {
      await config.fetchAllLensGroups()
      lensGroups.value?.map((group: any) => {
        const { ids, ...val } = manager.setAllEntities(group.lensGroup_data.lens_items);

        // @ts-ignore
        dataEntities.value = {
          entities: { ...dataEntities.value.entities, ...val.entities },
          ids: _.uniq([...dataEntities.value.ids, ...ids]),
        };
      });
      lens.value = manager.entitiesToArray(dataEntities.value);
    };

    router?.beforeEach((to, from, next) => {
      if (lensRoutes.includes(to?.name as string)) {
        if (!_.isEmpty(route?.params)) {
          const { cosmosId, locationId } = to.params;

          setSelectedLensItems({ cosmosId, locationId });
        }
        next();
      } else {
        next();
      }
    });

    onMounted(() => {
      const { cosmosId, locationId } = route?.params;
      if (!_.isEmpty(route?.params)) {
        setSelectedLensItems({ cosmosId, locationId });
      }
      setAllLens();
    });

    if (lensRoutes.includes(route?.name as string)) {
      // clearLensItem();
      setAllLens();
      const { cosmosId, locationId } = route?.params;
      if (!_.isEmpty(route?.params)) {
        setSelectedLensItems({ cosmosId, locationId });
      }
    }

    // when we change params
    watch(
      () => route?.params,
      (params) => {
        const { cosmosId, locationId } = params;
        setAllLens();
        if (!_.isEmpty(params)) {
          setSelectedLensItems({ cosmosId, locationId });
        }
      }
    );

    return {
      lens,
      dataEntities,
      selectedLensItems,
      clear,
      setAllLens,
      setSelectedLensItems,
      setAllOtherLens,
    };
  },
  {
    persist: false,
  }
);
// https://blog.logrocket.com/complex-vue-3-state-management-pinia/#getting-started-with-pinia
