<template>
  <div>
    <LensItemsPageHeader :imageName="'box'" :page="'Statistics Archive'" @refech-all="reload" />
    <div class="container px-2 py-4 mx-auto">
      <StorageListTable :tableData="file.csv" @tableData:download-file="downloadFile($event)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { FileConfig } from "@/index";
import StorageListTable from "@core/tables/StorageListTable.vue";
import { onMounted, reactive, computed, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

import { useFileStorageStore } from "@modules/admin/store/fileStorageStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";

import LensItemsPageHeader from "@core/components/banner/headers/LensItemsPageHeader.vue";
import { sleep } from "@/@use/libs/helpers";

const router = useRouter();
const lensGroup = useLensGroupStore();
const lens = useLensStore();
const file = useFileStorageStore();

const folder = import.meta.env.VITE_CONTAINER_ARCHIVES_STATISTICS || "archives-statistics";

const locationId = computed(() => router.currentRoute.value.params.locationId);
const lensItemId = computed(() => lens.selectedLensItems?.lens_id);
const cosmosId = computed(() => router.currentRoute.value.params.cosmosId);

const fileConfig = reactive({
  id: "",
  activation_date: "",
  clientName: "",
  appType: "",
  locationPoint: `${locationId.value}/${cosmosId.value}`,
  folder: folder,
}) as FileConfig;


/**
 * Définit la configuration du fichier.
 * Cette fonction récupère les informations nécessaires pour configurer le fichier, telles que l'ID de l'objectif sélectionné,
 * la date d'activation, le nom du client et le type d'application.
 * Elle utilise les fonctions fetchUserAllLensGroup(), setAllLens() et setSelectedLensItems() pour récupérer les données nécessaires.
 * @returns {Promise<void>} Une Promise qui se résout lorsque la configuration du fichier est terminée.
 */
const setFileConfig = async () => {
  await lensGroup.fetchUserAllLensGroup();
  lens.setAllLens();
  fileConfig.id = lens.selectedLensItems?.lens_id as string;
  fileConfig.activation_date = lensGroup.selectedLensGroup?.lensGroup_data.metadata.activation_datetime as string;
  fileConfig.clientName = lensGroup.selectedLensGroup?.lensGroup_data.metadata.tenant_id?.toLowerCase() as string;
  fileConfig.appType = lensGroup.selectedLensGroup?.lensGroup_data.metadata.application_type as string;
};

/**
 * Télécharge le fichier.
 * Cette fonction utilise la fonction fetchFileItem() pour récupérer le fichier à télécharger.
 * @param {any} $event - L'événement déclencheur de la fonction.
 * @returns {Promise<void>} Une Promise qui se résout lorsque le fichier est téléchargé.
 */
const downloadFile = async ($event: any) => {
 await file.fetchFileItem($event);
};

/**
 * Recharge les données.
 * Cette fonction met à jour la configuration du fichier en appelant la fonction setFileConfig().
 * Elle utilise les fonctions setSelectedLensItems(), setSelectedLensGroups() et fetchLensCSV() pour mettre à jour les données.
 * @returns {Promise<void>} Une Promise qui se résout lorsque les données sont rechargées.
 */
const reload = async () => {
  await setFileConfig();
  lens.setSelectedLensItems({ cosmosId: cosmosId.value, locationId: locationId.value });
  lensGroup.setSelectedLensGroups(locationId.value as string);
  if (lens.selectedLensItems && lensGroup.selectedLensGroup) {
    sleep(200);
    fileConfig.id = lensItemId.value as string;
    await file.fetchLensCSV(fileConfig);
  }
};

onMounted(async () => {
  await reload();
  if (fileConfig?.id) {
    await file.fetchLensCSV(fileConfig);
  }
});

onUnmounted(() => lens.clear());
</script>
