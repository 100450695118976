
const authRoutes = [
  /*{
    path: `/auth/login`,
    name: `auth-login`,
    component:  ()=> import('./Login.vue'),
    meta: { requiresAuth: false, layout: "home-layout" },
  },*/
  {
    path: `/auth/forgot-password`,
    name: `auth-forgot-password`,
    component:  ()=> import('./ForgotPassword.vue'),
    meta: { requiresAuth: false, layout: "home-layout" },
  },
  {
    path: `/auth/register`,
    name: `auth-register`,
    component:  ()=> import('./Register.vue'),
    meta: { requiresAuth: false, layout: "home-layout" },
  },
  {
    path: `/auth/reset`,
    name: `auth-reset`,
    component:  ()=> import('./Reset.vue'),
    meta: { requiresAuth: false, layout: "home-layout" },
  },
];

export default authRoutes;
