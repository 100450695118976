import { h } from "vue";
import { renderActionButtons } from "../table.helpers";

export const organizationConfigurationColumns = ({ emit }: { emit: any }) => {
  return [
    {
      label: "Display name",
      id: "org_display_name",
    },
    {
      label: "Email",
      id: "org_name",
      hidden: true,
    },
    {
      label: "Users emails",
      id: "org_users",
      hidden: false,
      render: (cell: any, row: any) => {
        const buttons =
          Array.isArray(cell) &&
          cell?.map((el) =>
            h(
              "span",
              {
                className: `${"m-auto block px-4 py-1 m-2 hover:text-gray-900 hover:font-bold"}`,
                row,
              },
              `${el.user_email}`
            )
          );
        return h("div", {}, buttons);
      },
    },
    {
      label: "Edit",
      width: "75px",
      render: (_: any, row: any) => renderActionButtons(row, emit),
    },
  ];
};
