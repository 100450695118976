import * as _ from "lodash";

import { LensGroup, User, UserUpdate } from "@/index";
import { Ref, computed, onMounted, ref } from "vue";

import { defineStore } from "pinia";
import { sleep } from "@/@use/libs/helpers";
import { useAPI } from "@use/useAPI";
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
import { useUiStore } from "@/@use/uiStore";

export const useConfigurationStore = defineStore("configuration", () => {
  const API = useAPI();
  const ui = useUiStore();
  const metadata = useMetadataStore();

  const _users = ref<User[]>();
  const selectedUser = ref<UserUpdate>();
  const _lensGroups = ref();
  const _roles = ref();
  const _organizations = ref();

  function clear() {
    selectedUser.value = undefined;
  }

  /**
   * Définit l'utilisateur sélectionné en fonction de son identifiant.
   *
   * @param userId L'identifiant de l'utilisateur à sélectionner.
   */
  function setSelectedUser(userId: number) {
    const response = _users.value?.find((user) => user?.id === userId);
    if (response) {
      const lensGroupList = response.user_lensGroups?.map((g: LensGroup) => g.id);
      selectedUser.value = {
        id: userId,
        username: response.username,
        user_email: response.user_email,
        user_fullname: response.user_fullname,
        user_role: response.user_role?.id,
        user_org: response.user_org?.id,
        user_lensGroups: lensGroupList,
      };
    }
  }

  /**
   * Récupère toutes les utilisateurs.
   * Cette fonction effectue les étapes suivantes :
   * 1. Définit l'état de chargement de l'interface utilisateur sur vrai.
   * 2. Efface les données existantes.
   * 3. Appelle l'API pour récupérer les données des utilisateurs.
   * 4. Met à jour la valeur des utilisateurs avec la réponse de l'API.
   * 5. Attend 200 millisecondes.
   * 6. Définit l'état de chargement de l'interface utilisateur sur faux.
   */
  async function fetchAllUsers() {
    ui.setLoadingState(true);
    clear();
    const response = (await API.getData("users")) as Ref<any>;
    _users.value = response?.value;
    await sleep(200);
    ui.setLoadingState(false);
  }

  /**
   * Récupère tous les groupes de lentilles.
   * Cette fonction effectue les étapes suivantes :
   * 1. Définit l'état de chargement de l'interface utilisateur sur true.
   * 2. Appelle l'API pour récupérer les données des groupes de lentilles.
   * 3. Stocke les données des groupes de lentilles dans la variable _lensGroups.
   * 4. Définit l'état de chargement de l'interface utilisateur sur false.
   */
  async function fetchAllLensGroups() {
    ui.setLoadingState(true);
    const response = (await API.getData("lensGroups")) as Ref<any>;
    _lensGroups.value = response?.value;
    ui.setLoadingState(false);
  }

  /**
   * Récupère tous les rôles.
   * Cette fonction effectue les étapes suivantes :
   * 1. Définit l'état de chargement de l'interface utilisateur sur true.
   * 2. Appelle l'API pour récupérer les données des rôles.
   * 3. Stocke les valeurs de réponse dans la variable _roles.
   * 4. Attend 200 millisecondes.
   * 5. Définit l'état de chargement de l'interface utilisateur sur false.
   */
  async function fetchAllRoles() {
    ui.setLoadingState(true);
    const response = (await API.getData("roles")) as Ref<any>;
    _roles.value = response?.value;
    await sleep(200);
    ui.setLoadingState(false);
  }
  /**
   * Récupère toutes les organisations.
   * Cette fonction effectue les étapes suivantes :
   * 1. Définit l'état de chargement de l'interface utilisateur sur true.
   * 2. Appelle l'API pour récupérer les données des organisations.
   * 3. Met à jour la valeur des organisations avec la réponse de l'API.
   * 4. Attend 200 millisecondes (simulant une pause).
   * 5. Définit l'état de chargement de l'interface utilisateur sur false.
   */
  async function fetchAllOrganizations() {
    ui.setLoadingState(true);
    const response = (await API.getData("organizations")) as Ref<any>;
    _organizations.value = response?.value;
    await sleep(200);
    ui.setLoadingState(false);
  }

  const users = computed(() => _.uniqBy(_users.value, "id"));
  const lensGroups = computed(() => _.uniqBy(_lensGroups.value, "location_id"));
  const roles = computed(() => _.uniqBy(_roles.value, "role_slug"));
  const organizations = computed(() => _.uniqBy(_organizations.value, "org_name"));

  onMounted(async () => {
    await fetchAllUsers();
    await fetchAllLensGroups();
    await fetchAllRoles();
    await fetchAllOrganizations();
    await metadata.fetchMasterConfigurations();
  });

  return {
    users,
    lensGroups,
    roles,
    organizations,
    selectedUser,
    setSelectedUser,
    fetchAllUsers,
    fetchAllOrganizations,
    clear,
    fetchAllLensGroups,
  };
});
