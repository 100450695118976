import * as _ from "lodash";

import { LensVariable } from "@/index";

/**
 *
 * @param lensVariables LensVariables []
 * @param channelName string
 * @returns {chart: number, channelName: string}
 *
 */
export const findDisplayNameByChannelName =
  (lensVariables: LensVariable[]) =>
  (channelName: string): { chart: number; channelName: string } => {
    const _selectVar = lensVariables.find((v: LensVariable) => v.vc_name === channelName);
    const cName = _selectVar ? _selectVar.disp_name : channelName;
    const chartN = _selectVar ? (_.isNumber(_selectVar.chart_number) ? _selectVar.chart_number : 0) : 0;
    return { chart: chartN, channelName: cName };
  };

/**
 * Trouve le nom d'affichage correspondant au nom du canal.
 * @param lensChartOptions - Les options de graphique de l'objectif.
 * @param channelName - Le nom du canal.
 * @param lensVariables - Les variables de l'objectif.
 * @returns L'objet contenant le numéro de graphique et le nom du canal correspondant.
 */
export const findDisplayNameByVCNameInnerChart = (
  lensChartOptions: any[],
  channelName: string,
  lensVariables: LensVariable[]
): { chart: number; channelName: string } => {
  const _select = lensChartOptions?.find((c: any) => c.virtual_channels.includes(channelName));
  const _selectVar = lensVariables?.find((v: LensVariable) => v.vc_name === channelName);
  const cName = _selectVar ? _selectVar.disp_name : channelName;
  const chartN = _select ? _select.chart_number : 0;
  return { chart: chartN, channelName: cName };
};

/**
 * Trouve le nom d'affichage correspondant au nom du canal dans le graphique.
 * @param lensVariables - Les variables de lens.
 * @param channelName - Le nom du canal.
 * @returns Un objet contenant le numéro du graphique, le nom du canal et le nom d'affichage correspondant.
 */
export const findDisplayNameByChannelNameChart = (
  lensVariables: LensVariable[],
  channelName: string
): { chart: number; channelName: string; dispName: string } => {
  let disp = channelName,
    chartN = 0;
  const _selectVar = lensVariables.find((v: LensVariable) => v.vc_name === channelName);
  if (_selectVar) {
    disp = _selectVar.disp_name;
    chartN = _.isNumber(_selectVar.chart_number) ? _selectVar.chart_number : 0;
  } else if (channelName.includes("__")) {
    const [_vc, _dataN] = channelName.split("__");
    const _selectVarInner = _vc ? lensVariables.find((v: LensVariable) => v.vc_name === _vc) : null;

    if (_selectVarInner) {
      disp = `${_selectVarInner.disp_name} ${_dataN}`;
      chartN = _.isNumber(_selectVarInner.chart_number) ? _selectVarInner.chart_number : 0;
    }
  }
  return { chart: chartN, channelName, dispName: disp };
};
