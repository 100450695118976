import * as _ from "lodash";

export const genericModuleDin41503 = ({
  location_id = "error",
  latlong = "50.535141|4.973460",
  location_name = "Micromega Device 1",
  group_name = "Micromega Device 1",
  timezone_python = "Europe/Brussels",
  timezone_javascript = "Europe/Brussels",
  group_description = "Rue trou du sart 10 5380 Fernelmont",
  device_sn = "20610011",
  client_name = "democlient",
  compute_fft = false,
}) => {
  if (location_id)
    return {
      id: `${location_id}-metadata-F2B`,
      latlong,
      device_sn,
      group_name,
      location_name,
      timezone_python,
      timezone_javascript,
      doc_type: "F2B",
      operator: "Name FirstName",
      tenant_id: _.lowerCase(_.trim(client_name)),
      contract_no: "NONE",
      location_id,
      tenant_name: "unallocated",
      affiliate_id: "",
      partitionKey: "metadata",
      organisations: _.uniq(["micromega", client_name]),
      affiliate_name: "",
      timezone_azure: "Romance Standard Time",
      application_type: "generic",
      report_logo_left: "logoMMD.png",
      group_description,
      report_logo_right: "logoAffiliate.png",
      time_format_azure: "dd-MM-yyyy HH:mm:ss",
      activation_datetime: new Date().toISOString(),
      lens_items: [
        {
          type: "monitor_processing",
          module: {
            "din4150-3": {
              enable: true,
              module_id: "din41503-1",
              line: 3,
              pdf_title: "Vibration",
              parameters: {
                channel_mapping: {
                  "PPV-X": "VC_3",
                  "PPV-Y": "VC_4",
                  "PPV-Z": "VC_5",
                },
              },

              compute_fft: compute_fft,
            },
          },
          invisible: false,
          lens_id: `${location_id}-din41503-1`,
          location_id: `${location_id}`,
          lens_metadata_id: `${location_id}-metadata-F2B`,
          message: "NONE",
          data_type: compute_fft === true ? "module-bursts-din41503-fft" : "module-bursts-din41503-zc",
          is_module: true,
          lens_name: compute_fft === true ? "module DIN4150-3 - FFT" : "module DIN4150-3",
          lens_charts: [],
          lens_status: "active",
          data_summary: "NONE",
          lens_queries: [],
          lens_variables: [],
          lens_description: compute_fft === true ? "module DIN4150-3 - FFT" : "module DIN4150-3",
          anomaly_appearing: false,
          anomaly_disapearing: false,
          lens_base_chart_type: [],
          burst_list_log_group_by: "NONE",
          lens_parameters_process: [],
          anomaly_notification_enable: false,
          lens_parameters_notification: [],
        },
        {
          invisible: false,
          type: "monitor_processing",
          module: null,
          lens_id: `${location_id}-generic-burst-2`,
          location_id: `${location_id}`,
          lens_metadata_id: `${location_id}-metadata-F2B`,
          message: "NONE",
          data_type: "generic-bursts",
          is_module: false,
          lens_name: "Bursts",
          lens_charts: [],
          lens_status: "active",
          data_summary: "NONE",
          lens_queries: [],
          lens_variables: [],
          lens_description: "Bursts",
          anomaly_appearing: false,
          anomaly_disapearing: false,
          lens_base_chart_type: [],
          burst_list_log_group_by: null,
          lens_parameters_process: [],
          anomaly_notification_enable: false,
          lens_parameters_notification: [],
        },
        {
          invisible: false,
          type: "monitor_processing",
          module: null,
          lens_id: `${location_id}-generic-stat-4`,
          location_id: `${location_id}`,
          lens_metadata_id: `${location_id}-metadata-F2B`,
          message:
            "#datetime #end #location_id / #location_name #end Transition from  #previous_state to #state_now #end Vibration Peak: #data_summary_user_unit #user_unit #end Serial number: #serial_number #end NOTE: #end #threshold_1 #end #threshold_2 #end #threshold_3 #end",
          data_type: "generic-statistics",
          is_module: false,
          lens_name: "Statistics",
          lens_charts: [
            {
              type: "stats_timeseries_multi_axes",
              title: "generic",
              lens_id: "E20-generic-stat-4",
              x_label: "x",
              x_range: [0, 0],
              y_label: "y",
              y_range: [0, 10],
              is_generic: true,
              is_markline: true,
              chart_number: 0,
              is_combined_graph: false,
              physical_quantity: "acceleration",
            },
            {
              type: "stats_timeseries_multi_axes",
              title: "generic",
              lens_id: "E20-generic-stat-4",
              x_label: "x",
              x_range: [0, 0],
              y_label: "y",
              y_range: [0, 10],
              is_generic: true,
              is_markline: false,
              chart_number: 1,
              is_combined_graph: true,
              physical_quantity: "acceleration",
            },
          ],
          lens_status: "active",
          data_summary: "NONE",
          lens_queries: [],
          lens_variables: [],
          lens_description: "Statistics",
          anomaly_appearing: true,
          anomaly_disapearing: false,
          lens_base_chart_type: ["stats_timeseries_multi_axes", "stats_timeseries_multi_axes"],
          burst_list_log_group_by: null,
          lens_parameters_process: [],
          anomaly_notification_enable: true,
          lens_parameters_notification: [],
        },
      ],
      recipients: [],
      lens_variables: [
        {
          vc_name: "VC_0",
          disp_name: "VB2-X",
          sensor_sn: "030420391",
          sensor_type: "IAC-STD-I-6G",
          physical_quantity: "velocity",
        },
        {
          vc_name: "VC_1",
          disp_name: "VB2-Y",
          sensor_sn: "030420391",
          sensor_type: "IAC-STD-I-6G",
          physical_quantity: "velocity",
        },
        {
          vc_name: "VC_2",
          disp_name: "VB2-Z",
          sensor_sn: "030420391",
          sensor_type: "IAC-STD-I-6G",
          physical_quantity: "velocity",
        },
        {
          vc_name: "VC_3",
          disp_name: "Velocity-X",
          sensor_sn: "030420391",
          sensor_type: "IAC-STD-I-6G",
          physical_quantity: "velocity",
        },
        {
          vc_name: "VC_4",
          disp_name: "Velocity-Y",
          sensor_sn: "030420391",
          sensor_type: "IAC-STD-I-6G",
          physical_quantity: "velocity",
        },
        {
          vc_name: "VC_5",
          disp_name: "Velocity-Z",
          sensor_sn: "030420391",
          sensor_type: "IAC-STD-I-6G",
          physical_quantity: "velocity",
        },
        {
          vc_name: "VC_6",
          disp_name: "not used 1",
          sensor_sn: "NONE",
          sensor_type: "NONE",
          physical_quantity: "acceleration",
        },
        {
          vc_name: "VC_7",
          disp_name: "not used 2",
          sensor_sn: "1",
          sensor_type: "NONE",
          physical_quantity: "acceleration",
        },
      ],
      physical_quantities: [
        {
          type: "acceleration",
          external_unit: "g",
          internal_unit: "g",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 3,
        },
        {
          type: "velocity",
          external_unit: "mm/s",
          internal_unit: "mm/s",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 3,
        },
        {
          type: "time",
          external_unit: "s",
          internal_unit: "s",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
        {
          type: "frequency",
          external_unit: "Hz",
          internal_unit: "Hz",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
        {
          type: "windspeed",
          external_unit: "m/s",
          internal_unit: "m/s",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 2,
        },
        {
          type: "winddir",
          external_unit: "deg",
          internal_unit: "deg",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
        {
          type: "displacement",
          external_unit: "mm",
          internal_unit: "mm",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 3,
        },
        {
          type: "pressure",
          external_unit: "Pa",
          internal_unit: "Pa",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
        {
          type: "overpressure",
          external_unit: "Pa",
          internal_unit: "Pa",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
        {
          type: "ratio_percent",
          external_unit: "%",
          internal_unit: "%",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
        {
          type: "particulate_concentration",
          external_unit: "mg/m3",
          internal_unit: "mg/m3",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 2,
        },
      ],
      display_report_generator: false,
      display_report_generator_pdf: false,
    };
};
