<script setup lang="ts">
import { ref, reactive } from "vue";
import { storeToRefs } from "pinia";
import { PlusIcon } from "@heroicons/vue/outline";
import * as _ from "lodash";

import MainHeading from "@core/shared/ui/headings/MainHeading.vue";
import OrganizationConfigurationTable from "@core/tables/configurations/OrganizationConfigurationTable.vue";
import OrgCreateUpdateForm from "@core/forms/OrgCreateUpdateForm.vue";
import Modal from "@core/shared/ui/Modal.vue";

import { useConfigurationStore } from "@modules/configuration/store/configurationStore";
import { Organization } from "@/index";
import { useAPI } from "@/@use/useAPI";
import OrganizationDelete from "./OrganizationDelete.vue";
import { sleep } from "@/@use/libs/helpers";
import { useUiStore } from "@/@use/uiStore";
import { organizationTemplate } from "./CosmosTemp/organization";
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";

const ui = useUiStore();
const API = useAPI();

const metadata = useMetadataStore();
const configurationStore = useConfigurationStore();
const { organizations } = storeToRefs(configurationStore);

const showCreateOrgModal = ref<boolean>(false);
const showUpdateOrgModal = ref<boolean>(false);
const showDeleteOrgModal = ref<boolean>(false);
const selectedOrganization = ref<Organization>();

const newOrganization = reactive({
  org_display_name: " ",
  org_name: " ",
  org_users: [],
});

function editSelectedOrganization(organization: Organization) {
  selectedOrganization.value = organization;
  // selectedOrganization.value.org_users =
  //   selectedOrganization.value.org_users?.map((u: any) => u.id);
  showUpdateOrgModal.value = !showUpdateOrgModal.value;
  delete selectedOrganization.value.deletedAt;
}

function deleteSelectedOrganization(organization: Organization) {
  selectedOrganization.value = organization;
  showDeleteOrgModal.value = !showDeleteOrgModal.value;
}

function resetCreation() {
  showCreateOrgModal.value = !showCreateOrgModal.value;
  newOrganization.org_display_name = "";
  newOrganization.org_name = "";
  newOrganization.org_users = [];
}

function resetSelectedOrganization() {
  selectedOrganization.value = {
    id: 0,
    org_name: "",
    org_display_name: "",
    org_users: [],
  };
}

/**
 * Prépare les configurations maîtres en ajoutant le nom de l'organisation à la liste des clients.
 *
 * @param {string} orgName - Le nom de l'organisation à ajouter à la liste des clients.
 * @returns {Object} - Les configurations maîtres mises à jour.
 */
const prepareMasterConfigurations = (orgName: string) => {
  return Object.assign({}, metadata.masterConfigurations, { client_list: _.uniq([...metadata.masterConfigurations.client_list, orgName]) });
};

/**
 * Crée une nouvelle organisation en utilisant les données fournies.
 *
 * @param formData - Les données de l'organisation à créer.
 * @returns Une promesse qui se résout lorsque la création de l'organisation est terminée.
 */
const createNewOrganization = async (formData: Partial<Organization>) => {
  ui.setLoadingState(true);

  // Convertit le nom de l'organisation en minuscules
  formData.org_name = formData?.org_name?.toLowerCase();

  // Crée un objet d'organisation à partir du nom fourni
  const _orgz = organizationTemplate(formData.org_name);

  // Prépare les configurations maîtres pour l'organisation
  const _masterConf = prepareMasterConfigurations(formData.org_name!);

  // Envoie les données d'organisation à CosmosDB
  const responseCosmos = await API.postData("configs/upsertCosmosData", _orgz);
  const responseMasterConf = await API.postData("configs/upsertCosmosData", _masterConf);

  // Envoie les données d'organisation au serveur
  const response = await API.postData("organizations", formData);

  // Réinitialise le formulaire de création
  resetCreation();

  // Si la création est réussie, met à jour la liste des organisations et attend 200ms
  if (response) {
    await configurationStore.fetchAllOrganizations();
    await sleep(200);
  }

  ui.setLoadingState(false);
};

/**
 * Met à jour une organisation avec les données fournies.
 *
 * @param {Partial<Organization>} formData - Les données de l'organisation à mettre à jour.
 * @returns {Promise<void>} - Une promesse qui se résout lorsque la mise à jour est terminée.
 */
const updateOrganization = async (formData: Partial<Organization>) => {
  ui.setLoadingState(true);

  // Convertit le nom de l'organisation en minuscules
  formData.org_name = formData?.org_name?.toLowerCase();

  // Envoie les données de l'organisation mise à jour à l'API
  const response = await API.putData("organizations", formData);

  // Réinitialise l'organisation sélectionnée
  resetSelectedOrganization();

  if (response) {
    // Récupère toutes les organisations à nouveau
    await configurationStore.fetchAllOrganizations();

    // Attend 200 millisecondes
    await sleep(200);
  }

  // Masque la fenêtre modale de mise à jour de l'organisation
  showUpdateOrgModal.value = false;

  // Définit l'état de chargement à false
  ui.setLoadingState(false);
};

/**
 * Supprime une organisation.
 *
 * Cette fonction est appelée lorsqu'un utilisateur souhaite supprimer une organisation.
 * Les étapes du code sont les suivantes :
 * 1. Définit l'état de chargement de l'interface utilisateur sur true.
 * 2. Vérifie si une organisation est sélectionnée.
 * 3. Cache la fenêtre modale de suppression de l'organisation.
 * 4. Appelle l'API pour supprimer les données de l'organisation sélectionnée.
 * 5. Réinitialise l'organisation sélectionnée.
 * 6. Si la suppression réussit, met à jour la liste des organisations et attend 200ms.
 * 7. Cache la fenêtre modale de suppression de l'organisation.
 * 8. Définit l'état de chargement de l'interface utilisateur sur false.
 */
const deleteOrganization = async () => {
  ui.setLoadingState(true);
  if (selectedOrganization.value) {
    showDeleteOrgModal.value = false;
    const response = await API.deleteData("organizations", selectedOrganization.value?.id);
    resetSelectedOrganization();
    if (response) {
      await configurationStore.fetchAllOrganizations();
      await sleep(200);
    }
    showDeleteOrgModal.value = false;
  }
  ui.setLoadingState(false);
};
</script>

<template>
  <div class="container px-2 py-4 mx-auto">
    <MainHeading title="Organizations list" subtitle="Add, update, delete organization from RDB">
      <template v-slot:button>
        <button
          @click="showCreateOrgModal = !showCreateOrgModal"
          class="uppercase inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
        >
          <PlusIcon class="h-6 w-6" />
          <span class="ml-4 flex items-start flex-col leading-none">{{ $t("configuration.createOrg") }}</span>
        </button>
      </template>
    </MainHeading>
    <OrganizationConfigurationTable
      :tableData="organizations"
      @tableData:toggle-edit-modal="editSelectedOrganization"
      @tableData:toggle-delete-modal="deleteSelectedOrganization"
    />
    <Modal :show="showCreateOrgModal" @reset="showCreateOrgModal = false">
      <OrgCreateUpdateForm :orgObjectData="newOrganization" :isNewOrganization="true" @submit-org-form="createNewOrganization" />
    </Modal>
    <Modal :show="showUpdateOrgModal" @reset="showUpdateOrgModal = false">
      <OrgCreateUpdateForm
        v-if="selectedOrganization?.org_name"
        :key="selectedOrganization?.org_name"
        :orgObjectData="selectedOrganization"
        :isNewOrganization="false"
        @submit-org-form="updateOrganization"
      />
    </Modal>
    <Modal :show="showDeleteOrgModal" @reset="showDeleteOrgModal = false">
      <OrganizationDelete :orgToDelete="selectedOrganization" @confirm="deleteOrganization" @reset="showDeleteOrgModal = false" />
    </Modal>
  </div>
</template>
<style scoped>
td {
  text-align: -webkit-center;
}
</style>
