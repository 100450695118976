import * as _ from "lodash";

const limitNp2074Interpolation = (f, ref_level) => {
  // Reforçadas
  const line1R_ref_level = 6.0; // f <= 10
  // Correntes
  const line2C_ref_level = 3.0; // f <= 10
  // Sensiveis
  const line3S_ref_level = 1.5; // f <= 10

  let level;

  if (f <= 10) {
    level = ref_level;
  } else if (f <= 40) {
    if (ref_level < line3S_ref_level) {
      level = (3 * ref_level) / line3S_ref_level;
    } else if (ref_level < line2C_ref_level) {
      level =
        3 +
        ((6 - 3) * (ref_level - line3S_ref_level)) /
          (line2C_ref_level - line3S_ref_level);
    } else if (ref_level < line1R_ref_level) {
      level =
        6 +
        ((12 - 6) * (ref_level - line2C_ref_level)) /
          (line1R_ref_level - line2C_ref_level);
    } else {
      level = (12 * ref_level) / line1R_ref_level;
    }
  } else {
    if (ref_level < line3S_ref_level) {
      level = (6 * ref_level) / line3S_ref_level;
    } else if (ref_level < line2C_ref_level) {
      level =
        6 +
        ((12 - 6) * (ref_level - line3S_ref_level)) /
          (line2C_ref_level - line3S_ref_level);
    } else if (ref_level < line1R_ref_level) {
      level =
        12 +
        ((40 - 12) * (ref_level - line2C_ref_level)) /
          (line1R_ref_level - line2C_ref_level);
    } else {
      level = (40 * ref_level) / line1R_ref_level;
    }
  }

  return level;
};

const baseRange = _.range(0, 101, 1);
const midRange10 = _.range(9.9, 10.1, 0.01);
const midRange40 = _.range(39.9, 40.1, 0.01);

const allRange = [...baseRange, ...midRange10, ...midRange40].sort(
  (a, b) => a - b
);

export const Np2074_L1_curve = allRange.map((point) => [
  point,
  limitNp2074Interpolation(point, 6),
]);
export const Np2074_L2_curve = allRange.map((point) => [
  point,
  limitNp2074Interpolation(point, 3),
]);
export const Np2074_L3_curve = allRange.map((point) => [
  point,
  limitNp2074Interpolation(point, 1.5),
]);
