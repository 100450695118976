<script setup lang="ts">
import { ref, onMounted } from "vue";
import TableData from "@core/components/tables/elements/TableData.vue";
import { useConfigStore } from "@/config/configStore";
import { storeToRefs } from "pinia";
import { userConfigurationColumns } from "./UserConfigurationTable";
import { KeyValue } from "@/index";

const props = defineProps<{
  tableData: any[];
}>();
const emit = defineEmits([
  "tableData:toggle-edit-modal",
  "tableData:toggle-delete-modal",
]);

const configStore = useConfigStore();
const { enumerationData } = storeToRefs(configStore);
const displayColumns = ref<any>();

function hasPermission(permissionName: string) {
  return enumerationData.value[permissionName];
}

onMounted(() => {
  displayColumns.value = userConfigurationColumns({
    emit,
  });
});
</script>

<template>
  <div>
    <TableData :columns="displayColumns" :rows="tableData" />
  </div>
</template>