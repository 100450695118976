export const titleCase = (str: string) => {
  const newStr = str.replace("lens", "measurement");
  let sentence = newStr.split("_");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0] + sentence[i].slice(1);
  }
  return sentence.join(" ");
};

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

/**
 * Convertit une liste en un objet avec une clé spécifiée.
 *
 * @param list - La liste à convertir en objet.
 * @param key - La clé à utiliser pour chaque élément de la liste.
 * @returns L'objet résultant avec la clé spécifiée pour chaque élément.
 */
export const listToObjectWithKey = (list = [], key = "id"): any => {
  return list.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, {});
};

/**
 * Détermine le type de formulaire en fonction de la valeur fournie.
 * @param value - La valeur à évaluer.
 * @returns Le type de formulaire correspondant à la valeur.
 */
const setformType = (value: any) => {
  if (Array.isArray(value)) {
    return;
  }
  const isEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (isEmail.test(value)) {
    return "input_email";
  }
  if (typeof value === "boolean") {
    return "input_checkbox";
  }
  if (typeof value === "number") {
    return "input_number";
  }
  return "input_text";
};

/**
 * Fichier contenant des fonctions utilitaires pour aider dans différentes tâches.
 */

export const helpers = {
  /**
   * Convertit une chaîne de caractères en notation de titre.
   * @param str - La chaîne de caractères à convertir.
   * @returns La chaîne de caractères convertie en notation de titre.
   */
  titleCase: function (str: string) {
    return titleCase(str);
  },

  /**
   * Définit le type de formulaire.
   * @param value - La valeur du type de formulaire.
   * @returns La valeur du type de formulaire définie.
   */
  setformType: function (value: any) {
    return setformType(value);
  },

  /**
   * Arrondit un nombre à un certain nombre de décimales.
   * @param point - Le nombre à arrondir.
   * @param decimal - Le nombre de décimales (par défaut: 2).
   * @returns Le nombre arrondi avec le nombre de décimales spécifié.
   */
  toDecimals: function (point: number | string, decimal = 2): string {
    //@ts-ignore
    return point && parseFloat(point?.toFixed(decimal));
  },

  /**
   * Trie une liste dans l'ordre croissant en utilisant la propriété "location_id" des objets.
   * @param list - La liste à trier.
   * @returns La liste triée dans l'ordre croissant.
   */
  sortAsc: function (list: any[]) {
    return list.sort((a: any, b: any): number => {
      return collator.compare(a.location_id, b.location_id);
    });
  },

  /**
   * Trie une liste dans l'ordre décroissant en utilisant la propriété "location_id" des objets.
   * @param list - La liste à trier.
   * @returns La liste triée dans l'ordre décroissant.
   */
  sortDesc: function (list: any[]) {
    return list.sort((a: any, b: any): number => {
      return collator.compare(a.location_id, b.location_id);
    });
  },

  /**
   * Insère ou met à jour un objet dans une liste en utilisant l'ID comme clé.
   * @param list - La liste d'objets.
   * @returns Une fonction qui prend l'ID et le modificateur de l'objet à insérer ou mettre à jour.
   * @example
   * const upsert = helpers.upsertObject(myList);
   * upsert('123', { name: 'John' });
   */
  upsertObject: (list: any[]) => {
    return (id: string, modifier: any) => {
      const objectFromList = listToObjectWithKey(list as any);
      const selectedObject = { ...objectFromList[id], ...modifier };
      return { ...objectFromList, [id]: selectedObject };
    };
  },

  /**
   * Remplace un objet dans une liste en utilisant l'ID comme clé.
   * @param list - La liste d'objets.
   * @returns Une fonction qui prend l'ID et le modificateur de l'objet à remplacer.
   * @example
   * const replace = helpers.replaceObject(myList);
   * replace('123', { name: 'John' });
   */
  replaceObject: (list: any[]) => {
    return (id: string, modifier: any) => {
      const objectFromList = listToObjectWithKey(list as any);
      const selectedObject = modifier;
      return { ...objectFromList, [id]: selectedObject };
    };
  },

  /**
   * Supprime les propriétés d'un objet qui commencent par une certaine chaîne de caractères.
   * @param startWith - La chaîne de caractères avec laquelle les propriétés doivent commencer.
   * @returns Une fonction qui prend l'objet à modifier.
   * @example
   * const removeStartWith = helpers.removeStartWith('prefix_');
   * removeStartWith(myObject);
   */
  removeStartWith: (startWith: string) => {
    return function (_object: any) {
      for (var key in _object) {
        if (key.startsWith(startWith)) {
          delete _object[key];
        }
      }
      return _object;
    };
  },
};

/**
 * Vérifie si un objet contient des propriétés falsy.
 *
 * @param object - L'objet à vérifier.
 * @returns Retourne true si l'objet contient des propriétés falsy, sinon retourne false.
 */
export const hasFalsyProperty = (object: any) => {
  // @ts-ignore NOTE: check if key is undefined
  return object.hasOwnProperty(undefined) || object.hasOwnProperty("") || object.hasOwnProperty(null);
};

export const deleteFalsyProperty = (object: any) => {
  // @ts-ignore
  delete object[""];
  // @ts-ignore
  delete object[undefined];
  // @ts-ignore
  delete object[null];
  return object;
};

/**
 * Trie les clés d'un objet dans l'ordre alphabétique inverse.
 *
 * @param object - L'objet dont les clés doivent être triées.
 * @returns Un nouvel objet avec les clés triées dans l'ordre alphabétique inverse.
 */
export const sortObjectEntriesKeys = (object: any) => Object.fromEntries(Object.entries(object).sort().reverse());

/**
 * Filtre les clés d'un objet en fonction des tableaux non vides associés.
 *
 * @param object - L'objet dont les clés doivent être filtrées.
 * @returns Un tableau contenant les clés de l'objet dont les tableaux associés ne sont pas vides.
 */
export const filterObjectKeyByNotEmptyArray = (object: Object) =>
  Object.entries(object)
    .filter((el) => {
      return el[1].length > 0 ? true : false;
    })
    .map((e) => e[0]);

/**
 * Attendre pendant une durée spécifiée en millisecondes.
 * @param ms - La durée en millisecondes à attendre.
 * @returns Une promesse qui se résout après la durée spécifiée.
 */
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

/**
 * Retourne l'index du premier élément dans une liste qui correspond à une clé et une valeur données.
 *
 * @param list - La liste dans laquelle rechercher l'élément.
 * @param key - La clé à vérifier dans chaque élément de la liste.
 * @param value - La valeur à comparer avec la valeur de la clé dans chaque élément de la liste.
 * @returns L'index du premier élément correspondant, ou -1 si aucun élément ne correspond.
 */
export const getIndex = (list: any[], { key = "", value = "" }) => list.findIndex((el) => el && el[key] === value);

