import { ColumnTable } from "@/index";
import Switch from "./elements/Switch.vue";
import { h } from "vue";
import { i18n } from "@/locales";

export const burstListTableColumns = ({
  fetchDataFromCosmosById,
  toDecimalPPV,
  toDecimal,
  toDate,
}: {
  fetchDataFromCosmosById: Function;
  toDecimalPPV: Function;
  toDecimal: Function;
  toDate: Function;
}): ColumnTable[] => [
  {
    label: i18n.global.t("tables.burst.visible"),
    id: "visible",
    sortable: true,
    isKey: true,
    render: () => Switch,

    props: (cell: any) => ({ enabled: cell }),
    // no need to use function ?
    events: (cell: any, row: any, $event: any) => () => {
      return {...row, visible: $event}
    },

    eventName: "update:switch",
  },

  {
    label: i18n.global.t("tables.show"),
    id: "show",
    render: (_: any, row: any) => {
      return h(
        "button",
        {
          className: "m-auto inline-block px-4 py-1 bg-blue-200 rounded shadow-xs cursor-pointer hover:bg-blue-500 hover:text-gray-100 text-sm",
          onClick: (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            fetchDataFromCosmosById(row.name);
          },
        },
        "show"
      );
    },
  },
  {
    label: i18n.global.t("tables.burst.datetime"),
    id: "datetime",
    sortable: true,
    isKey: true,
    formatter: toDate,
    sortItemType: "date",
    sortItemKey: "datetime",
  },
  {
    label: "id",
    id: "name",
    hidden: true,
    // sortable: true,
    // isKey: true,
  },
  {
    label: i18n.global.t("tables.burst.pvs_ppv"),
    id: "pvs_ppv",
    sortable: true,
    isKey: true,
    formatter: toDecimalPPV,
    sortItemType: "string",
    sortItemKey: "pvs_ppv",
  },
  {
    label: i18n.global.t("tables.burst.pvs_time"),
    id: "pvs_time",
    sortable: true,
    // isKey: true,
    formatter: toDecimalPPV,
    sortItemType: "string",
    sortItemKey: "pvs_time",
  },
  {
    id: "",
    label: "PPV-TRAN",
    width: "200px",
    hidden: false,
    columns: [
      {
        label: i18n.global.t("tables.burst.freq"),
        id: "x_freq",
        width: "100px",
        formatter: toDecimal,
        // sortable: true,
      },
      {
        label: i18n.global.t("tables.burst.ppv"),
        id: "x_ppv",
        width: "100px",
        formatter: toDecimalPPV,
        // sortable: true,
      },
      // {
      //   label: i18n.global.t("tables.burst.time"),
      //   id: "x_time",
      //   width: "100px",
      //   formatter: toDecimal,
      //   // sortable: true,
      // },
    ],
  },
  {
    id: "",
    label: "PPV-LONG",
    width: "200px",
    hidden: false,
    columns: [
      {
        label: i18n.global.t("tables.burst.freq"),
        id: "y_freq",
        width: "100px",
        formatter: toDecimal,
        // sortable: true,
      },
      {
        label: i18n.global.t("tables.burst.ppv"),
        id: "y_ppv",
        width: "100px",
        formatter: toDecimalPPV,
        // sortable: true,
      },
      // {
      //   label: i18n.global.t("tables.burst.time"),
      //   id: "y_time",
      //   width: "100px",
      //   formatter: toDecimal,
      //   // sortable: true,
      // },
    ],
  },
  {
    id: "",
    label: "PPV-VERT",
    width: "200px",
    hidden: false,
    columns: [
      {
        label: i18n.global.t("tables.burst.freq"),
        id: "z_freq",
        width: "100px",
        formatter: toDecimal,
        // sortable: true,
      },
      {
        label: i18n.global.t("tables.burst.ppv"),
        id: "z_ppv",
        width: "100px",
        formatter: toDecimalPPV,
        // sortable: true,
      },
      // {
      //   label: i18n.global.t("tables.burst.time"),
      //   id: "z_time",
      //   width: "100px",
      //   formatter: toDecimal,
      //   // sortable: true,
      // },
    ],
  },
];
