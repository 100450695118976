import Bursts from "./bursts/Bursts.vue";
import BurstsCsv from "./bursts/BurstsCsv.vue";
import BurstsCustomReports from "./bursts/BurstsCustomReports.vue";
import BurstsGeneric from "./bursts/BurstsGeneric.vue";
import BurstsModular from "./bursts/BurstsModular.vue";
import BurstsReports from "./bursts/BurstsReports.vue";
import Stats from "./statistics/Stats.vue";
import StatsCsv from "./statistics/StatsCsv.vue";
import StatsLogs from "./statistics/StatsLogs.vue";
import { optionsRoutes } from "./options/options.routes";
import { settingRoutes } from "./settings/setting.routes";

const lensRoutes = (dataType: string) => {
  const base = [
    {
      path: `/admin/lens/${dataType}/:locationId/:cosmosId`,
      name: `admin-lens-${dataType}-id`,
      component: dataType === "generic-bursts" && Bursts,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
    {
      path: `/admin/lens/${dataType}/:locationId/:cosmosId`,
      name: `admin-lens-${dataType}-id`,
      component: dataType === "generic-statistics" && Stats,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
    {
      path: `/admin/lens/${dataType}/csv/:locationId/:cosmosId`,
      name: `admin-lens-${dataType}-csv-id`,
      component: dataType === "generic-bursts" && BurstsCsv,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
    {
      path: `/admin/lens/${dataType}/csv/:locationId/:cosmosId`,
      name: `admin-lens-${dataType}-csv-id`,
      component: dataType === "generic-statistics" && StatsCsv,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
  ];
  const other =
    dataType === "generic-bursts"
      ? [
          {
            path: `/admin/lens/${dataType}/report/:locationId/:cosmosId`,
            name: `admin-lens-${dataType}-report-id`,
            component: BurstsReports,
            meta: { requiresAuth: true, layout: null, isHome: false },
          },
        ]
      : [
          {
            path: `/admin/lens/${dataType}/log/:locationId/:cosmosId`,
            name: `admin-lens-${dataType}-log-id`,
            component: StatsLogs,
            meta: { requiresAuth: true, layout: null, isHome: false },
          },
        ];

  return [...base, other];
};

const lensGenericRoutes = (dataType: string) => {
  return [
    {
      path: `/admin/lens/${dataType}/:locationId/:cosmosId`,
      name: `admin-lens-${dataType}-id`,
      component: BurstsGeneric,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
    {
      path: `/admin/lens/${dataType}/csv/:locationId/:cosmosId`,
      name: `admin-lens-${dataType}-csv-id`,
      component: BurstsCsv,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
    {
      path: `/admin/lens/${dataType}/report/:locationId/:cosmosId`,
      name: `admin-lens-${dataType}-report-id`,
      component: BurstsReports,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
  ];
};

const lensGenericModularRoutes = (dataType: string) => {
  return [
    {
      path: `/admin/lens/${dataType}/:locationId/:cosmosId/:module`,
      name: `admin-lens-${dataType}-id`,
      component: BurstsModular,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
    {
      path: `/admin/lens/${dataType}/csv/:locationId/:cosmosId/:module`,
      name: `admin-lens-${dataType}-csv-id`,
      component: BurstsCsv,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
    {
      path: `/admin/lens/${dataType}/report/:locationId/:cosmosId/:module`,
      name: `admin-lens-${dataType}-report-id`,
      component: BurstsReports,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
    {
      path: `/admin/lens/${dataType}/custom/report/:locationId/:cosmosId/:module/:url`,
      name: `admin-lens-${dataType}-custom-report-id`,
      component: BurstsCustomReports,
      meta: { requiresAuth: true, layout: null, isHome: false },
    },
  ];
};

const burstsRoutes = lensRoutes("bursts");
const statsRoutes = lensRoutes("statistics");
const genericStatsRoutes = lensRoutes("generic-statistics");
const genericBurstRoutes = lensGenericRoutes("generic-bursts");

const genericBurstModularRoutes = lensGenericModularRoutes("bursts-modular");

const adminRoutes = [
  {
    path: "/admin",
    name: "adminIndex",
    component: () => import("./Admin.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: "/global-map",
    name: "globalMap",
    component: () => import("./shared/GlobalMap.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  ...burstsRoutes,
  ...statsRoutes,
  ...genericStatsRoutes,
  ...genericBurstRoutes,
  ...genericBurstModularRoutes,
  ...settingRoutes,
  ...optionsRoutes,
];

export default adminRoutes;
