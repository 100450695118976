<template>
  <div>
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <!-- Heroicon name: outline/exclamation -->
          <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div class="mt-4 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-bold text-gray-900" id="modal-headline">{{ $t("modal.deleteUser") }}</h3>
          <p class="text-gray-600 text-md mt-2">
            <span class="font-semi">username: </span>{{ userToDelete?.username }}<br/>
            <span class="font-semi">email: </span>{{ userToDelete?.user_email }}
          </p>
          <div class="mt-4">
            <p class="text-sm text-gray-500">
              {{ $t("modal.deleteConfirmMsg") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <button
        @click="userDeleteConfirmed"
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
      >
        {{ $t("ui.delete") }}
      </button>
      <button
        @click="emit('reset')"
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
      >
        {{ $t("ui.cancel") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ userToDelete: any }>();
const emit = defineEmits(["confirm", "reset"]);


const userDeleteConfirmed = () => {
  emit("confirm");
};
</script>

<style scoped></style>
