import { Ref, ref } from "vue";

import { Query } from "@/index";
import { QueryParam } from "@/index";
import { defineStore } from "pinia";
import { requestModeler } from "@/config/requestModeler";
import { useAPI } from "@use/useAPI";
import { useConfigStore } from "@/config/configStore";
import { useLensStore } from "@modules/admin/store/lensStore";

export const useNotificationLogStore = defineStore(
  "notificationLog",
  () => {
    const API = useAPI();
    const configs = useConfigStore().enumerationData;
    const lens = useLensStore();

    const notifications = ref();

    /**
     * Récupère les notifications à partir de Cosmos DB.
     *
     * @returns Une Promise qui se résout avec les notifications récupérées.
     */
    const fetchNotifications = async () => {
      const cosmosId = lens.selectedLensItems?.lens_id;
      const partitionKey = "notification-log";
      const notificationLogQuery = lens.selectedLensItems?.lens_queries?.find(
        (el: Query) => el.query_type === configs.cosmosQueryTypeEnum.STATS_NOTIFICATION_LOG
      );

      const paramsList = [
        {
          key: configs.cosmosQueryParamsEnum.COSMOS_ID,
          value: cosmosId,
        },
      ] as QueryParam[];

      const query = requestModeler(paramsList, notificationLogQuery?.query_content as string);
      if (query) {
        const response = (await API.fetchFromCosmosWithquery({
          partitionKey,
          cosmosQuery: query,
        })) as Ref<any>;
        if (response?.value) {
          notifications.value = response?.value.map((el: any) => {
            const res = { ...el, ...el.data_return };
            delete res.data_return;
            return res;
          });
        }
      }
    };

    return {
      notifications,
      fetchNotifications,
    };
  },
  {
    persist: true,
  }
);
