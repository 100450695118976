import * as _ from "lodash";

import { LensVariable } from "@/index";

/**
 * Crée une série de graphique de dispersion à axes multiples.
 *
 * @param tables - Les tables de données à utiliser.
 * @param tablesEntries - Les entrées des tables à utiliser.
 * @param lens_variables - Les variables de lens à utiliser.
 * @returns Une série de graphique de dispersion à axes multiples.
 */
export const createMultipleAxesScatterPlotSeries = (
  tables:any[] = [],
  tablesEntries: any[],
  { lens_variables }: { lens_variables : LensVariable[]}
) => {
  const findInVariables = (axe: string) => {
    const ax = axe[0].split("_")[0];
    if (lens_variables.length > 0) {
      const isDisplay = lens_variables.find((v) =>
        v.vc_name.toLowerCase().includes(ax)
      );
      return !!isDisplay ? isDisplay.disp_name : ax.toUpperCase();
    }
  };
  const COLOR_ALL = ["#338aff", "#f53630", "#49e6ce", "#61f325"];
  const series = tablesEntries.map((entry, index) => {
    let currentTable : any[] = [];
    tables.map((table) => {
      currentTable.push([table[entry[0]], table[entry[1]]]);
    });

    return {
      type: "scatter",
      symbolSize: 7,
      // HOTFIX : mettre un P devant le name
      name: `${findInVariables(entry)}`,
      color: COLOR_ALL[index],
      data: currentTable,
    };
  });

  return _.isEmpty(tables) ? [] : series;
};
