<template>
  <div v-if="props.chartObj.id">
    <v-chart class="chart" ref="chart" :key="props.chartObj.id" :option="{ ...chartOptions, ...dataSet }" />
  </div>
</template>

<script setup lang="ts">
import { LineChart, LinesChart } from "echarts/charts";
import { use } from "echarts/core";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import { CanvasRenderer } from "echarts/renderers";

import {
  DataZoomComponent,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  PolarComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";

import { PhysicalQuantity } from "@/index";
import VChart from "vue-echarts";

use([
  LineChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  ToolboxComponent,
  DataZoomComponent,
  MarkLineComponent,
]);

// @ts-ignore
import { computed, reactive, ref } from "vue";
// @ts-ignore
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
// @ts-ignore
import { statChartConfig } from "../settings/statChartSettings";
//@ts-ignore
import { createToaster } from "@meforma/vue-toaster";

const metadata = useMetadataStore();
const chart = ref();

const props = defineProps<{
  chartObj: any;
}>();

const toaster = createToaster({
  position: "top-right",
});

const physicalQuantity = computed(() => metadata.physicalQuantities);
const getPhysicalByType = (type: string) => {
  return physicalQuantity.value?.find((ph: PhysicalQuantity) => ph.type === type);
};

const dataSet = reactive({
  dataset: {
    dimensions: props.chartObj.chartData.columns,
    source: props.chartObj.chartData.rows,
  },
});

const chartOptions = computed(() => {
  const physicalQ = getPhysicalByType(props.chartObj.chart.physical_quantity);
  if (physicalQ) {
    return statChartConfig({
      physicalQ,
      keys: props.chartObj.key,
      limites: props.chartObj.limites,
      chart: props.chartObj.chart,
      type: "stats",
      variables: props.chartObj.variables,
    });
  } else {
    toaster.error(`Error! Please verify your configuration`);
    return {};
  }
});

// onMounted(() => {
//   chart.value.setOption({
//     ...dataSet,
//     ...chartOptions,
//   });
// });
</script>

<style scoped>
.chart {
  height: 650px;
  min-width: 764px;
}
</style>
