<template>
  <div class="flex flex-col space-y-2 my-4 w-full">
    <div class="flex justify-between">
      <label :for="fieldObj.id" class="block text-sm font-medium text-gray-700" :key="fieldObj.id">
        {{ fieldObj.title }}
      </label>
    </div>
    <div class="mt-2">
      <Multiselect
        v-model="inputValue"
        :placeholder="'Search or add ' + fieldObj.title"
        label="name"
        track-by="code"
        :options="options"
        :multiple="false"
        :taggable="false"
        :disabled="fieldObj.disabled || props.isDisabled"
      ></Multiselect>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FieldSelectObject } from "@/index";
import { ref, watch, onMounted } from "vue";
// @ts-ignore
import Multiselect from "vue-multiselect";
import { useDebounceFn } from "@vueuse/core";

const emit = defineEmits(["edited-input"]);
const props = defineProps<{
  fieldObj: FieldSelectObject;
  isDisabled?: boolean;
}>();

const inputValue = ref(props.fieldObj?.value);
const options = ref(props.fieldObj?.options);

const rebuildEntries = (fieldObj: FieldSelectObject) => {
  const parentRef = fieldObj.parentRef?.local !== fieldObj.parentRef?.root ? fieldObj.parentRef?.local : null;
  const val = inputValue.value?.value;
  return {
    id: fieldObj.id,
    key: fieldObj.parentRef?.root,
    localKey: fieldObj.parentRef && Object.keys(fieldObj.parentRef?.local)[1],
    title: fieldObj.key,
    type: "input_select_single",
    value: {
      [fieldObj.key]: val,
      // @ts-ignore
      ...parentRef,
    },
  };
};

const compose = () => {
  const newValue = rebuildEntries(props.fieldObj);
  emit("edited-input", newValue);
};
onMounted(() => {
  compose();
});

watch(inputValue, (val) => {
  compose();
});
</script>
