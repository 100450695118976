<template>
  <div>
    <label class="text-base font-medium text-gray-900" :key="fieldObj.id">{{
      fieldObj.title
    }}</label>
    <fieldset class="mt-2">
      <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
        <div v-for="item in fieldObj.range" class="flex items-center">
          <input
            :key="item.id"
            type="radio"
            :name="fieldObj.key"
            :id="item.item"
            v-model="item.value"
            :checked="item.value"
            autofocus
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            @change="debounce"
          />
          <label
            :for="item.id"
            class="ml-3 block text-sm font-medium text-gray-700"
            :key="item.id"
          >
            {{ item.value }}
          </label>
        </div>
      </div>
    </fieldset>
  </div>
</template>
<script lang="ts" setup>
import { FieldObject } from "@/index";
import { useDebounceFn } from "@vueuse/core";

const props = defineProps<{
  fieldObj: FieldObject;
}>();

const emit = defineEmits(["edited-input"]);

const debounce = useDebounceFn((term) => {
  const valuetoDispatch = {
    ...props.fieldObj,
    value: term,
    id: props.fieldObj.id,
    error: props.fieldObj.error,
  };
  emit("edited-input", valuetoDispatch);
}, 700);
</script>