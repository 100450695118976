<template>
  <div class="relative">
    <div class="container mx-auto items-center px-5 pb-24">
      <template
        v-for="c in chartList?.sort((a: any, b: any) => (a.chart?.chart_index === b.chart?.chart_index ? 1 :( a.chart?.chart_index > b.chart?.chart_index ? 1 : -1)))"
      >
        <component v-if="c" :key="c.id" :is="getCurrentChart(c.chart.type)" :chartObj="c"></component>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import StatTimeseriesMultiAxesChart from "@core/charts/elements/StatTimeseriesMultiAxesChart.vue";
// @ts-ignore
import { useConfigStore } from "@/config/configStore";
const configStore = useConfigStore();

const props = defineProps<{
  chartList: any;
}>();

/**
 * Renvoie le composant de graphique correspondant au type spécifié.
 *
 * @param {string} type - Le type de graphique.
 * @returns {Component} - Le composant de graphique correspondant.
 */
const getCurrentChart = (type: string) => {
  // Permet de switcher entre les graphs en cas de plusieurs graphs par page
  if (type === configStore.enumerationData.chartTypeEnum.STATS_TIMESERIES_1AXES) return StatTimeseriesMultiAxesChart;
  if (type === configStore.enumerationData.chartTypeEnum.STATS_TIMESERIES_2AXES) return StatTimeseriesMultiAxesChart;
  if (type === configStore.enumerationData.chartTypeEnum.STATS_TIMESERIES_3AXES) return StatTimeseriesMultiAxesChart;
  if (type === configStore.enumerationData.chartTypeEnum.STATS_TIMESERIES_MULTI_AXES) return StatTimeseriesMultiAxesChart;

  // Default
  return StatTimeseriesMultiAxesChart;
};
</script>
