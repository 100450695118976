import * as _ from "lodash";

/**
 * Divise une chaîne de caractères en deux parties en utilisant un séparateur spécifié.
 *
 * @param str La chaîne de caractères à diviser.
 * @param separator Le séparateur utilisé pour diviser la chaîne (par défaut: "__").
 * @returns Un objet contenant la partie initiale de la chaîne (head) et la partie restante (tail).
 */
function splitToObject(str: string, separator = "__") {
  if(str && typeof str === 'string'){
  // @ts-ignore
 const [head, tail] = str?.split(separator);
  // @ts-ignore
    return { head, tail };
  }
}

/**
 * Trie une liste de chaînes de caractères contenant des décimales.
 *
 * @param list - La liste de chaînes de caractères à trier.
 * @param separator - Le séparateur utilisé pour diviser les chaînes de caractères en parties.
 * @returns Une nouvelle liste de chaînes de caractères triées.
 */
export function sortStringWithDecimals(list: string[], separator = "__") {
  return _.sortBy(
    list.map((el) => splitToObject(el, separator)),
    ["tail", "head"]
  ).map((s) => `${s?.head}${separator}${s?.tail}`);
}
