<template>
  <div>
    <div class="flex justify-between">
      <label :for="fieldObj.key" class="block text-sm font-medium text-gray-700">{{ fieldObj.title }}</label>
      <span class="text-sm text-gray-500" v-if="fieldObj.hint">{{ fieldObj.hint }}</span>
    </div>
    <div class="mt-2">
      <input
        type="text"
        :name="fieldObj.key"
        :id="fieldObj.key"
        v-model="fieldObj.value"
        :key="fieldObj.id"
        autofocus
        autocomplete="off"
        @keyup="debounce"
        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
    </div>
    <p class="mt-2 text-sm text-red-400" v-if="error">
      {{ error }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { FieldObject } from "@/index";
import { useDebounceFn } from "@vueuse/core";
import { phone } from "phone";
import { ref } from "vue";

const props = defineProps<{
  fieldObj: FieldObject;
}>();

const emit = defineEmits(["edited-input"]);
const error = ref(props.fieldObj.error);
const debounce = useDebounceFn(() => {
  error.value = phone(props.fieldObj.value).isValid ? undefined : "Invalid phone number !!!";

  const valuetoDispatch = {
    ...props.fieldObj,
    value: phone(props.fieldObj.value).phoneNumber,
    id: props.fieldObj.id,
    error: error.value,
  };

  emit("edited-input", valuetoDispatch);
}, 400);
</script>
