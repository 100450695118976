import * as _ from "lodash";

import { LensChart, LensVariable, Limit, PhysicalQuantity } from "@/index";
import { axisLabelFormatter, commonLineConfig, dataZoomStats, defaultTz, hasMinMaxYRange, series, toolbox, tooltip } from "./defaultSettings";

import { findDisplayNameByChannelNameChart } from "../dataFormatters/shared/findDisplayNameByChannelName";
import moment from "moment-timezone";

/**
 * Définit les paramètres de l'axe Y pour le graphique statistique.
 *
 * @param physicalQ La quantité physique associée au graphique.
 * @param chart Le graphique LensChart.
 * @returns Les paramètres de l'axe Y.
 */
export const YStatAxis = ({ physicalQ, chart }: { physicalQ: PhysicalQuantity; chart: LensChart }) => {
  return {
    yAxis: {
      type: "value",
      name: _.upperCase(chart.y_label),
      axisLabel: {
        formatter: (value: any) => axisLabelFormatter(value, physicalQ),
      },
      ...commonLineConfig(),
      ...hasMinMaxYRange({ chart }),
    },
  };
};

/**
 * Définit les paramètres de l'axe X pour le graphique statistique.
 * @param tz Le fuseau horaire utilisé pour formater les valeurs de l'axe X.
 * @param timeUnit L'unité de temps utilisée pour déterminer l'intervalle de l'axe X.
 * @returns Les options de configuration de l'axe X.
 */
export const XStatAxis = ({ tz = defaultTz, timeUnit = "hour" }) => {
  const interval = timeUnit === "hour" ? 60 * 60 * 1000 : 1000;
  return {
    xAxis: {
      type: "category",
      showSymbol: false,
      name: "",
      axisLine: { onZero: false },
      minInterval: interval, // 10 minutes
      maxInterval: interval * 2, // 2h
      axisLabel: {
        formatter: (value: any) => {
          return moment(value)
            .tz(tz)
            .format("HH:mm:ss" || import.meta.env.VITE_DEFAULT_TIME_FORMAT);
        },
      },
    },
  };
};

/**
 * Configuration de graphique statistique.
 * @param {Object} options - Les options de configuration.
 * @param {PhysicalQuantity} options.physicalQ - La quantité physique.
 * @param {string[]} options.keys - Les clés.
 * @param {LensChart} options.chart - Le graphique.
 * @param {string} options.type - Le type.
 * @param {Limit[]} [options.limites=[]] - Les limites.
 * @param {LensVariable[]} options.variables - Les variables.
 * @returns {Object} - La configuration du graphique statistique.
 */
export const statChartConfig = ({
  physicalQ,
  keys,
  chart,
  type,
  limites = [],
  variables,
}: {
  physicalQ: PhysicalQuantity;
  keys: string[];
  chart: LensChart;
  type: string;
  limites: Limit[];
  variables: LensVariable[];
}) => {
  const tz = defaultTz;
  return {
    title: {
      text: _.upperCase(chart.title),
      left: "center",
      top: "1px",
      textStyle: {
        fontSize: 25,
      },
    },

    legend: {
      type: "plain",
      left: "center",
      data: keys.map((key: string) => key),
      formatter: function (value: string) {
        const res = findDisplayNameByChannelNameChart(variables, value);
        return _.upperCase(res.dispName);
      },
      bottom: "110px",
    },

    grid: {
      left: "100px",
      right: "60px",
      top: "35px",
      bottom: "230px",
      containLabel: false,
    },
    ...tooltip({ physicalQ, type, dsf: 1000, variables }),
    ...toolbox(),
    ...dataZoomStats(),
    ...series({ keys, chart, limites }),
    ...XStatAxis({ tz: defaultTz, timeUnit: "hour" }),
    ...YStatAxis({ physicalQ, chart }),
  };
};
