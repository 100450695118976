<template>
  <template v-if="col?.render">
    <template v-if="hasProps(col) && hasEvents(col)">
      <component :is="render(col, row)" v-bind="innerBinds(col, row)" @[eventName]="innerEvents($event)" :key="row[col.id]"></component>
    </template>
    <template v-else-if="hasProps(col) && !hasEvents(col)">
      <component :is="render(col, row)" v-bind="innerBinds(col, row)" :key="row[col.id]"></component>
    </template>
    <template v-if="!hasProps(col) && hasEvents(col)">
      <component :is="render(col, row)" @[eventName]="innerEvents($event)" :key="row[col.id]"></component>
    </template>
    <template v-if="!hasProps(col) && !hasEvents(col)">
      <component :is="render(col, row)" :key="row[col.id]"></component>
    </template>
  </template>

  <template v-else-if="col?.formatter">
    {{ col.id && col?.formatter(row[col.id], row) }}
  </template>
  <template v-else>
    {{ col?.id && row[col?.id] }}
  </template>
</template>

<script setup lang="ts">
import { ColumnTable } from "@/index";
import { computed, unref } from "vue";
import useTable from "./useTable";

const props = defineProps<{
  setting?: any;
  col?: ColumnTable;
  row?: any;
  primaryKey?: string;
}>();
const table = useTable();

const eventName = computed(() => props.col?.eventName || "table:event");

const $emit = defineEmits(["table:event"])

const hasProps = (col: ColumnTable) => {
  return col && col.props && col.props && typeof col.props === "function";
};

const hasEvents = (col: ColumnTable) => {
  return col && col.events && col.events && typeof col.events === "function";
};

const render = (col: ColumnTable, row: any) => {
  //@ts-ignore
  return col.render(row[col.id], row);
};

const innerEvents = ($event: any) => {
  //@ts-ignore
 const updated = props?.col?.events(props?.row[props?.col.id], props?.row, $event).call();

 table.updateDataFromEvents(eventName.value, updated)
};

const innerBinds = (col: ColumnTable, row: any) => {
  //@ts-ignore
  return col.props(row[col.id], row);
};
</script>
