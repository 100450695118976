import { h } from "vue";
import { renderActionButtons } from "../table.helpers";

export const recipientConfigurationColumns = ({ emit }: { emit: any }) => {
  return [
    {
      label: "Id",
      id: "id",
      hidden: true,
    },
    {
      label: "Full name",
      id: "name",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Phone",
      id: "phone",
    },
    {
      label: "Edit",
      width: "200px",
      render: (_: any, row: any) => renderActionButtons(row, emit),
    },
  ];
};
