<template>
  <div class="mt-10">
    <WysiwygEditor :value="props.innerData?.message" @edit-value="editorContentChange" class="my-6" />

    <div>
      <button
        type="submit"
        @click="$emit('save', outerText)"
        class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
      >
        {{ $t("ui.save") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { Marked } from "@ts-stack/markdown";
import WysiwygEditor from "@core/comments/WysiwygEditor.vue";

const $emit = defineEmits(["input", "save"]);
const props = defineProps({
  innerData: {
    type: Object,
    required: true,
  },
});

// const innerText = ref(`
// `);

const outerText = ref(props.innerData);

const editorContentChange = ($event: any) => {
  outerText.value = {
    ...props.innerData,
    message: $event,
  };

  return outerText.value;
};

// https://dev.to/thormeier/build-your-own-wysiwyg-markdown-editor-for-vue-318j
</script>
<style>
.button {
  @apply border-2;
  @apply border-gray-300;
  @apply rounded-lg;
  @apply px-3 py-1;
  @apply mb-3 mr-3;
}
.button:hover {
  @apply border-green-300;
}

.display h1 {
  @apply text-2xl;
  @apply font-bold;
  @apply pb-4;
}
.display p {
  @apply pb-4;
}
.display p {
  @apply pb-4;
}
.display ul {
  @apply ml-6;
  @apply list-disc;
}
.display ol {
  @apply ml-6;
  @apply list-decimal;
}
</style>
