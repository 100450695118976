import * as _ from "lodash";

import { LensChart, LensParametersNotification, LensVariable, MeasurementDocumentStat, MultipleStreamData, StatMeasurementData } from "@/index";
import { createGenericDataName, splitDs } from "../../fnUtils";

import { findDisplayNameByChannelName } from "../findDisplayNameByChannelName";
import moment from "moment";

const defaultTz = import.meta.env.VITE_DEFAULT_TIMEZONE;


/**
 * Formatte les données de plusieurs flux combinés.
 *
 * @param stats Les statistiques à formater.
 * @param lens_variables Les variables de l'objectif.
 * @param lens_charts Les graphiques de l'objectif.
 * @param lens_parameters_notification Les paramètres de notification de l'objectif.
 * @param dataNames Les noms des données.
 * @returns Les données formatées des flux combinés.
 */
export const multipleStreamCombinedDataFormat = (
  stats: any,
  {
    lens_variables,
    lens_charts,
    lens_parameters_notification,
  }: {
    lens_variables: LensVariable[];
    lens_charts: LensChart[];
    lens_parameters_notification: LensParametersNotification[];
  },
  dataNames: string[]
): MultipleStreamData => {
  let newStats: MultipleStreamData = {
    virtual_channel_names: [],
    p_stats: 0,
    n_stats: 0,
    id: "",
    timezone: "",
    lens_variables: [],
    lens_charts: [],
    lens_parameters_notification: [],
    data_name: "",
  };

  const result = stats.map((doc: StatMeasurementData, docidx: number) => {
    const virtual_channel_names = splitDs(doc.virtual_channel_names) as string[];

    newStats.virtual_channel_names = virtual_channel_names;

    if (docidx === 0) {
      newStats.id = doc.id;
    }

    newStats.data_name = "combined_graph";
    newStats.p_stats = parseInt(doc.p_stats, 10);
    newStats.n_stats = parseInt(doc.n_stats, 10);
    newStats.timezone = doc.timezone_javascript || defaultTz;
    newStats.lens_variables = lens_variables;
    newStats.lens_charts = lens_charts;
    newStats.lens_parameters_notification = lens_parameters_notification;

    const n_stat: number = parseInt(doc.n_stats, 10);
    // on regroupe des données dans une liste de liste par channel
    let allDisplayChannelNames = [] as string[];
    let channel_stat_name = [] as any[]

    const allDatas = dataNames.map((dataName) => {
      // @ts-ignore
      const data = splitDs(doc[dataName] as any) as string[];

      const channel_stat = _.chunk(data, n_stat);

      const _channel_stat_name = channel_stat.map((channel, chanidx) => {
        const channelName = createGenericDataName(virtual_channel_names[chanidx], dataName);
        const varInfos = findDisplayNameByChannelName(lens_variables)(channelName);

        // NOTE : JIRA RD-267
        const acq_start_dt = splitDs(doc.acq_start_dt)[chanidx] || (doc.acq_dt as string);

        allDisplayChannelNames.push(varInfos.channelName);
        // @ts-ignore
        newStats[varInfos.channelName] = [] as any[];

        // On reconstitue la date des statistiques ici
        const dataTimeChannel = channel.map((stat, index) => {
          const calcDate = parseInt(doc.p_stats, 10) * (index + 1);
          //@ts-ignore
          const time = `${moment(acq_start_dt).utc().add(calcDate, "s").format()}`;

          // Pour chaque virtual channel name on ajoute la stat correspondante , le datetine et l'index
          return {
            time,
            [varInfos.channelName]: stat,
          };
        });
        return { [varInfos.channelName]: dataTimeChannel };
      });
      channel_stat_name.push(_channel_stat_name);

      return {
        index: docidx,
        allDisplayChannelNames,
        channel_stat_name: channel_stat_name.flat(1),
      };
    });

    allDatas.map((elem, index) => {
      [...channel_stat_name, ...elem.channel_stat_name];
    });
    newStats.virtual_channel_names = allDisplayChannelNames;
    return {
      index: docidx,
      allDisplayChannelNames,
      channel_stat_name: channel_stat_name.flat(1),
    };
  });
  //return result

  result.forEach((d: any) => {
    return d.allDisplayChannelNames.forEach((n: string, i: number) => {
      // @ts-ignore
      newStats[n].push(...Object.values(d.channel_stat_name[i]));
      // @ts-ignore
      newStats[n] = _.flatten(newStats[n]);
    });
  });
  return newStats;
};
