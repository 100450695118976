import * as _ from "lodash";

/**
 * Fusionne les tableaux d'objets en utilisant une clé commune et retourne les éléments fusionnés.
 *
 * @param key La clé utilisée pour fusionner les objets.
 * @returns Une fonction qui prend les tableaux d'objets à fusionner et renvoie les éléments fusionnés.
 */
export const filterAndMergeWith = (key: string) => {
  return (...arrays: any[]) => {
    const combinedData = [];
    const allItems = arrays.flat();

    while (allItems.length > 0) {
      const currentItem = allItems.shift(); // Prend le premier élément et le supprime de la liste
      if (!currentItem.hasOwnProperty(key)) continue;

      const matchingItems = allItems?.filter((item) => item[key] === currentItem[key]);

      if (matchingItems.length > 0) {
        combinedData.push(Object.assign({}, currentItem, ...matchingItems));

        // Supprimer les éléments fusionnés de allItems
        matchingItems.forEach((matchedItem) => {
          const index = allItems.indexOf(matchedItem);
          if (index !== -1) {
            allItems.splice(index, 1);
          }
        });
      }
    }

    return combinedData;
  };
};


/**
 * Recherche et fusionne un nouvel élément avec un tableau d'éléments existant en fonction d'une condition de recherche.
 *
 * @param items - Le tableau d'éléments existant.
 * @param newItem - Le nouvel élément à fusionner.
 * @param findCondition - La condition de recherche pour trouver l'élément à fusionner.
 * @returns Le tableau d'éléments mis à jour avec le nouvel élément fusionné, ou le tableau d'origine si aucune correspondance n'est trouvée.
 */
export const findAndMergeWith = (items: any[], newItem: any[], findCondition: any) => {
  const index = _.findIndex(items, findCondition);

  if (index !== -1) {
    const clonedItems = _.cloneDeep(items);
    clonedItems[index] = _.merge({}, clonedItems[index], newItem);
    return clonedItems;
  }

  return items; // Return original array if no item matches the condition
};
