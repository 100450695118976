import * as _ from "lodash";

import { CalendarDisplay, CalendarResponse } from "@/index";
import { Ref, onUnmounted, reactive, ref, watch } from "vue";

import { defineStore } from "pinia";
import { sleep } from "@/@use/libs/helpers";
import { useAPI } from "@use/useAPI";
import { useCalendarData } from "./useCalendarData";
import { useDateTimeStore } from "@use/useDatetime";

/**
 * Hook personnalisé pour gérer l'état et les fonctionnalités liées au calendrier.
 *
 * @returns Les propriétés et les méthodes du store du calendrier.
 */
export const useCalendarsStore = defineStore("calendar", () => {
  const calendarData = useCalendarData();
  const datetime = useDateTimeStore();
  const API = useAPI();

  const calendars = ref<CalendarDisplay[]>();
  const rawCalendar = ref<CalendarResponse[]>();
  const todayCalendar = ref<CalendarResponse>();

  const allCalendarInStore = reactive({
    ids: [],
    entities: {},
  });

  /**
   * Ajoute des entités à la liste.
   *
   * @param id - L'identifiant de l'entité.
   * @param data - Les données de l'entité.
   */
  const addEntitiesToList = (id: string, data: any[]) => {
    // @ts-ignore
    allCalendarInStore.ids = _.uniq([...allCalendarInStore.ids, id]);
    allCalendarInStore.entities = _.set(allCalendarInStore.entities, `${id}`, data);
  };

  /**
   * Charge les données d'un calendrier à partir de l'entité correspondante.
   *
   * @param id - L'identifiant du calendrier à charger.
   * @returns L'entité du calendrier correspondant à l'identifiant spécifié, ou undefined si l'entité n'est pas trouvée.
   */
  const loadFromEntities = (id: string) => {
    if (allCalendarInStore.ids.find((_id: string) => id === _id)) {
      //@ts-ignore
      return allCalendarInStore.entities[id];
    }
  };

  /**
   * Formate les données du calendrier.
   *
   * @returns Les données du calendrier formatées.
   */
  const formatCalendarData = () => {
    const popOverText = "Intensity : ";
    if (!_.isEmpty(todayCalendar.value)) {
      const data = !_.isEmpty(rawCalendar.value) ? rawCalendar.value?.concat(todayCalendar.value) : [todayCalendar.value];

      const result = data
        ?.filter((d: any) => (d.date ? true : false))
        ?.map((d: any) => {
          return {
            highlight: {
              color: "green",
              fillMode: "solid",
            },
            popover: {
              label: `${popOverText} ${d.max_day_code || "0"}`,
            },
            // On ne prends que la première partie de la date
            date: d.date,
            //count: d.max_day_code,
            dates: [new Date(datetime.helpers.dateOnly(d.date))],
          };
        });
      return (calendars.value = _.uniqBy(result, "date"));
    }
  };

  /**
   * Récupère tous les calendriers en fonction des paramètres spécifiés.
   *
   * @param {Object} options - Les options de récupération des calendriers.
   * @param {string} options.dataType - Le type de données.
   * @param {string} options.locationPoint - Le point de localisation.
   * @param {string} options.deviceTz - Le fuseau horaire du dispositif.
   * @returns {Promise<void>} - Une Promise qui se résout lorsque les calendriers sont récupérés avec succès.
   */
  const fetchAllCalendars = async ({ dataType, locationPoint, deviceTz }: { dataType: string; locationPoint: string; deviceTz: string }) => {
    const _entity = loadFromEntities(locationPoint);
    if (_entity && _entity.length > 0) return (rawCalendar.value = _entity);

    const allCalendarQuery = calendarData(dataType).fetchSelectedLensCalendar();
    const todayCalendarQuery = calendarData(dataType).fetchSelectedLensTodayMaxEvent(locationPoint, deviceTz);

    if (allCalendarQuery && todayCalendarQuery) {
      // const _todayCalendar = await API.fetchFromCosmosWithquery(todayCalendarQuery);
      // const _calendarList = await API.fetchFromCosmosWithquery(allCalendarQuery);

      await Promise.all([API.fetchFromCosmosWithquery(todayCalendarQuery), API.fetchFromCosmosWithquery(allCalendarQuery)]).then((res) => {
        const [_todayCalendar, _calendarList] = res;

        if (_calendarList?.value && _todayCalendar?.value) {
          const _raw =
            _calendarList.value.length > 0
              ? _calendarList.value[0].data_calendar.map((el: CalendarResponse) => {
                  return { ...el, color: el.max_day_code };
                })
              : [];
          rawCalendar.value = [..._raw, ..._todayCalendar.value];
          todayCalendar.value = _todayCalendar.value;
          addEntitiesToList(locationPoint, rawCalendar.value);
          formatCalendarData();
        }
      });
    }
  };

  const resetCalendarData = () => {
    calendars.value = [];
    rawCalendar.value = [];
  };

  watch(
    () => rawCalendar.value,
    () => {
      formatCalendarData();
    }
  );

  onUnmounted(() => {
    resetCalendarData();
  });

  return { calendars, rawCalendar, todayCalendar, allCalendarInStore, formatCalendarData, fetchAllCalendars, resetCalendarData };
});

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/#getting-started-with-pinia
