export const din4150BaseBurstChartOptions = (location_id = "error") => ({
  id: `${location_id}-metadata-generic-chart-bursts-options`,
  partitionKey: "metadata",
  burst_ids: ["burst_0"],
  options: {
    burst_0: {
      lens_chart: [
        {
          type: "bursts_timeseries_3axes",
          title: "velocity",
          x_label: "x",
          x_range: [0, 0],
          y_label: "y",
          y_range: [0, 2],
          is_generic: true,
          is_markline: true,
          chart_number: 0,
          is_combined_graph: false,
          physical_quantity: "velocity",
          virtual_channels: ["VC_3", "VC_4", "VC_5"],
        },
      ],
    },
  },
});

export const genericBaseBurstChartOptions = (location_id = "error") => ({
  id: `${location_id}-metadata-generic-chart-bursts-options`,
  partitionKey: "metadata",
  burst_ids: ["burst_0"],
  options: {
    burst_0: {
      lens_chart: [
        {
          type: "bursts_timeseries_3axes",
          title: "velocity",
          x_label: "x",
          x_range: [0, 0],
          y_label: "y",
          y_range: [0, 2],
          is_generic: true,
          is_markline: true,
          chart_number: 0,
          is_combined_graph: false,
          physical_quantity: "velocity",
          virtual_channels: ["VC_1", "VC_2", "VC_3"],
        },
      ],
    },
  },
});
