import { createRouter, createWebHistory } from "vue-router";

import adminRoutes from "@modules/admin/admin.routes";
import authRoutes from "@modules/auth/auth.routes";
import configurationRoutes from '@modules/configuration/configuration.routes';
import { reject } from "lodash";

// import Home from '@modules/pages/Home.vue
const routes = [
  ...authRoutes,
  ...adminRoutes,
  ...configurationRoutes,
  {
    path: "/",
    name: "home",
    meta: { requiresAuth: false, layout: "home-layout", isHome: true },
    component: () => import("@modules/pages/Home.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    meta: { requiresAuth: false, layout: "home-layout" },
    component: () => import("@modules/pages/Page404.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(),
  // @ts-ignore
  routes,
});
const isAuth = (meta: any) => {
  const auth = localStorage.getItem("auth");

  const token = auth && JSON.parse(auth) && JSON.parse(auth).token?.accessToken;

  return meta.requiresAuth === true ? !!token : true;
};

router.beforeEach((to, from, next) => {
  if (to.name === ("home")) {
    next();
  } else {
    if (isAuth(to.meta)) {
      next();
    } else {
      router?.push({ name: "home" });
    }
  }
});

export default router;
