<template>
  <div>
    <LensItemsPageHeader
      :imageName="'aaa-log'"
      :page="'Notifications logs'"
      @refech-all=""
    />
    <div class="container px-2 py-4 mx-auto">
      <!-- <NotifLogTable :tableData="notifications.notifications" /> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, watchEffect, computed, onMounted, reactive } from "vue";
// import NotifLogTable from "@core/tables/NotifLogTable.vue";
import LensItemsPageHeader from "@core/components/banner/headers/LensItemsPageHeader.vue";

import { useNotificationLogStore } from "@modules/admin/store/notificationLogStore";

const notifications = useNotificationLogStore();

onMounted(async() => {
  await notifications.fetchNotifications();
});
</script>

<style scoped></style>
