/**
 * Options génériques pour les alarmes statistiques.
 * @param location_id L'identifiant de l'emplacement (par défaut: "error").
 * @returns Les options génériques pour les alarmes statistiques.
 */
export const genericAlarmOption = ( location_id = "error") => ({
  id: `${location_id}-metadata-generic-alarms-statistics-options`,
  lens_id: `${location_id}-generic-stat-4`,
  partitionKey: "metadata",
  doc_type: "generic_options",
  lens_name: "Statistics",
  data_type: "generic-statistics",
  options: [

  ],
});
