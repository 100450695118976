import * as _ from "lodash";

import { BurstDataForLineMultiplesAxe, LensChart, LensParametersNotification, LensVariable } from "@/index";

import { createChartGraphForBurst } from "./shared/groupBurstByChartNumber";
import { formatAndGroupBurstForMultiCharts } from "./shared/formatAndGroupBurstForMultiGenericCharts";
import { formatAndGroupBurstForMultiGenericChartsFFT } from "./shared/formatAndGroupBurstForMultiGenericChartsFFT";

const defaultTz = import.meta.env.VITE_DEFAULT_TIMEZONE;

/**
 * Formate les données pour le graphique de rafales.
 *
 * @param ressources - Les ressources à formater.
 * @param lens_variables - Les variables de l'objectif.
 * @param lens_charts - Les graphiques de l'objectif.
 * @param lens_parameters_notification - Les paramètres de notification de l'objectif.
 * @returns Les données formatées pour le graphique de rafales.
 */
const formatDataForBurstChart = (
  ressources: any,
  {
    lens_variables,
    lens_charts,
    lens_parameters_notification,
  }: {
    lens_variables: LensVariable[];
    lens_charts: LensChart[];
    lens_parameters_notification: LensParametersNotification[];
  }
) => {
  const doc = ressources;

  // On vérifie bien que c'est un bursts
  if (_.isEmpty(doc.n_raw_data) || _.isEmpty(doc.n_raw_data)) {
    return Error("this is not a bursts");
  }
  let burstWithChartNumber: any;

  const burstByChartsNumber = formatAndGroupBurstForMultiCharts(doc, {
    lens_variables,
  });
  const burstByChartsNumberFFT = formatAndGroupBurstForMultiGenericChartsFFT(doc, {
    lens_variables,
  });

  burstWithChartNumber = {
    ...burstByChartsNumber,
    ...burstByChartsNumberFFT,
  };

  return lens_charts
    .map((chart) => {
      const currVariable = lens_variables.filter((l) => l.chart_number === chart.chart_number);
      let firstCol = "time";
      const columns = currVariable.map((el) => {
        firstCol = el.disp_name.toLowerCase().includes("fft") ? "freq" : "time";
        return el.disp_name;
      });

      if (burstWithChartNumber[chart.chart_number]) {
        return {
          id: doc.id,
          info: {
            name: doc.name,
            frequencyPerSecond: doc.adc_fs,
            timezone: doc.timezone_javascript || defaultTz,
            xUnit: firstCol === "freq" ? "Hz" : "ms",
          },
          chartData: {
            columns: [firstCol, ...columns],
            rows: createChartGraphForBurst(firstCol, burstWithChartNumber[chart.chart_number]),
          },
          key: columns,
          variables: lens_variables,
          chart,
          limites: lens_parameters_notification,
        };
      }
    })
    .filter((el) => el !== undefined);
};
/**
function formatByLensChart(
  burstWithChartNumber: any,
  { id, name, adc_fs, timezone_javascript }: { id: string; name: string; adc_fs: string; timezone_javascript: string },
  {
    lens_variables,
    lens_charts,
    lens_parameters_notification,
  }: {
    lens_variables: LensVariable[];
    lens_charts: LensChart[];
    lens_parameters_notification: LensParametersNotification[];
  }
) {
  let firstCol = "time";

  return lens_charts
    .map((chart) => {
      const currVariable = lens_variables.filter((l) => l.chart_number === chart.chart_number);

      const columns = currVariable.map((el) => {
        firstCol = el.disp_name.toLowerCase().includes("fft") ? "freq" : "time";
        return el.disp_name;
      });

      if (burstWithChartNumber[chart.chart_number]) {
        return {
          id: id,
          info: {
            name: name,
            frequencyPerSecond: adc_fs,
            timezone: timezone_javascript || defaultTz,
          },
          chartData: {
            columns: [firstCol, ...columns],
            rows: createChartGraphForBurst(firstCol, burstWithChartNumber[chart.chart_number]),
          },
          key: columns,
          variables: lens_variables,
          chart,
          limites: lens_parameters_notification,
        };
      }
    })
    .filter((el) => el !== undefined);
}
 */
/**
 * Formate les données pour le graphique de rafales.
 *
 * @param data Les données à formater.
 * @param lensInfos Les informations sur les lens.
 * @returns Les données formatées pour le graphique de rafales.
 */
export function graphListFormatterBurst(data: any, lensInfos: any) {
  const ret = formatDataForBurstChart(data, lensInfos);
  return ret;
}
