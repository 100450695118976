/*let example =
  'SELECT c.id, c.peak_data as data, c.acq_start_dt, c.n_channels, c.n_stats, c.p_stats, c.virtual_channel_dsf, c.virtual_channel_names, c.virtual_channel_units, c.name FROM c WHERE (c.msg_dt between "@startingDate" AND "@endDate}") AND c.name="@tlmName}" ORDER BY c.msg_dt asc'
let data = ""

const list = [
  { key: "@startingDate", value: "startingDate" },
  { key: "@endDate", value: "endDate" },
  { key: "@tlmName", value: "tlmName" },
]
*/

import { QueryParam } from "..";

// Request modeler transforme une string avec des variables en string avec les valeurs de ses variables modifiers
/**
 * Modéliseur de requête.
 * Cette fonction prend une liste de paramètres de requête et une requête sous forme de chaîne de caractères,
 * et remplace les clés des paramètres de requête par leurs valeurs correspondantes dans la requête.
 *
 * @param queryParamsList - La liste des paramètres de requête.
 * @param query - La requête sous forme de chaîne de caractères.
 * @returns La requête modélisée avec les valeurs des paramètres de requête.
 */
export const requestModeler = (queryParamsList: QueryParam[], query: string) => {
  let data = "";
  if (!query) {
    console.error("No Query set in request modeler");
    return "";
  }

  if (!Array.isArray(queryParamsList)) {
    console.error("No queryParamsList set in request modeler");
    return "";
  }

  Array.isArray(queryParamsList) &&
    queryParamsList.forEach((item: QueryParam) => {
      data = query?.replace(item.key, item.value);
      query = data;
    });
  return data;
};
