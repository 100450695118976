import { Query, QueryParam } from "@/index";
import { onMounted, reactive, ref } from "vue";

import { requestModeler } from "@/config/requestModeler";
import { useConfigStore } from "@/config/configStore";
import { useDateTimeStore } from "@use/useDatetime";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";

/**
 * Hook personnalisé pour récupérer les données du calendrier.
 * @param dataType - Le type de données à récupérer.
 * @returns Les données du calendrier et les fonctions pour récupérer les données du calendrier sélectionné.
 */
export const useCalendarData = () => (dataType: string) => {
  const configs = useConfigStore().enumerationData;
  const lens = useLensStore();
  const lensGroup = useLensGroupStore();
  const datetime = useDateTimeStore();

  const calendarEvent = ref();
  const calendarTodayEvent = ref();

  const type = ref(dataType);

  const today =
    type.value === "bursts"
      ? configs.cosmosQueryTypeEnum?.BURST_TODAY_EVENT_CALENDAR
      : configs.cosmosQueryTypeEnum?.STATS_TODAY_EVENT_CALENDAR;
  const otherDay =
    type.value === "bursts"
      ? configs.cosmosQueryTypeEnum?.BURST_CALENDAR
      : configs.cosmosQueryTypeEnum?.STATS_CALENDAR;

  const activation_datetime =
    lensGroup.selectedLensGroup?.lensGroup_data.metadata.activation_datetime;

  calendarEvent.value = lens.selectedLensItems?.lens_queries?.find(
    (el: Query) => el.query_type === otherDay
  ) as Query;

  calendarTodayEvent.value = lens.selectedLensItems?.lens_queries?.find(
    (el: Query) => el.query_type === today
  ) as Query;

  const calendarCosmosId = lens.selectedLensItems?.lens_id
    ? lens.selectedLensItems?.lens_id + "-calendar"
    : "";

  /**
   * Récupère les données du calendrier pour la lens sélectionnée.
   *
   * @returns {Object} Les données de requête pour récupérer le calendrier.
   */
  const fetchSelectedLensCalendar = () => {
    if (lens.selectedLensItems?.lens_id && activation_datetime) {
      const paramsList: QueryParam[] = [
        { key: configs.cosmosQueryParamsEnum.ID, value: calendarCosmosId },
        {
          key: configs.cosmosQueryParamsEnum.STARTING_DATE,
          value: activation_datetime,
        },
      ];
      const query = requestModeler(paramsList, calendarEvent.value?.query_content);

      return {
        partitionKey: "calendar",
        cosmosQuery: query,
      };
    }
  };

  /**
   * Récupère les événements maximum d'aujourd'hui pour la lens sélectionnée.
   *
   * @param locationPoint - Le point de localisation.
   * @param deviceTz - Le fuseau horaire du dispositif.
   * @returns L'objet contenant la partitionKey et la requête Cosmos.
   */
  const fetchSelectedLensTodayMaxEvent = (locationPoint: string, deviceTz: string) => {
    if (lens.selectedLensItems?.lens_id) {
      const { startingDate, endDate } = datetime.helpers.startEnd24hourFromDate(
        datetime.helpers.today,
        deviceTz
      );
      const paramsList = [
        {
          key: configs.cosmosQueryParamsEnum.STARTING_DATE,
          value: startingDate,
        },
        {
          key: configs.cosmosQueryParamsEnum.END_DATE,
          value: endDate,
        },
        {
          key: configs.cosmosQueryParamsEnum.END_DATE,
          value: endDate,
        },
        {
          key: configs.cosmosQueryParamsEnum.TLM_NAME,
          value: `${lens.selectedLensItems?.lens_id}`,
        },
      ];

      const query = requestModeler(paramsList, calendarTodayEvent.value?.query_content);
      return {
        partitionKey: locationPoint,
        cosmosQuery: query,
      };
    }
  };

  return {
    calendarData: {
      dataType: type,
      calendarEvent,
      calendarTodayEvent,
      calendarCosmosId,
    },
    fetchSelectedLensCalendar,
    fetchSelectedLensTodayMaxEvent,
  };
};
