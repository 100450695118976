<template>
  <section class="text-gray-700 body-font relative">
    <div class="container mx-auto items-center px-5 pb-24" v-if="calendars?.length && calendars?.length > 0">
      <v-calendar
        :key="props.locationPoint"
        expanded
        @dayclick="dayClick"
        :columns="columns"
        :rows="rows"
        :is-expanded="true"
        :attributes="calendarDataAttrs"
        ref="calendar"
        :initial-page="initialPage"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useDateTimeStore } from "@use/useDatetime";
import moment from "moment";
import { onMounted, ref, watch, watchEffect, onUnmounted } from "vue";
import { useCalendarsStore } from "./calendarStore";
import { useCalendarData } from "./useCalendarData";
import { useScreens } from "vue-screen-utils";
// @ts-ignore
import { createToaster } from "@meforma/vue-toaster";
import { storeToRefs } from "pinia";

const toaster = createToaster({
  position: "top-right",
});

const calendarStore = useCalendarsStore();
const calendarData = useCalendarData();
const datetime = useDateTimeStore();

const { calendars } = storeToRefs(calendarStore);
const initialPage = { month: moment().month(), year: moment().year() };

const { mapCurrent } = useScreens({
  xs: "0px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
});

const columns = mapCurrent({ default: 3, lg: 3 });
const rows = mapCurrent({ default: 1, lg: 1 });

const props = defineProps<{
  locationPoint: string;
  deviceTz: string;
  dataType: string;
}>();

const emit = defineEmits(["day-click"]);

const calendarDataAttrs = ref();
const calendarDataConf = ref();
const calendar = ref();

/**
 * Récupère tous les calendriers.
 * @async
 * @function fetchAllCalendars
 * @returns {Promise<void>}
 */
const fetchAllCalendars = async () => {
  await calendarStore.fetchAllCalendars({
    dataType: props.dataType,
    locationPoint: props.locationPoint,
    deviceTz: props.deviceTz,
  });
  calendarDataAttrs.value = calendarStore.calendars;
};

/**
 * Définit la date sélectionnée sur le calendrier.
 * @function setSelectedDateOnCalendar
 * @param {any} selectedDate - La date sélectionnée.
 * @returns {void}
 */
const setSelectedDateOnCalendar = (selectedDate: any) => {
  calendarDataAttrs.value = calendarStore.calendars;

  if (calendarDataAttrs.value.length > 0) {
    const selectedEntry = calendarDataAttrs.value?.find((entry: any) => entry.date && entry.date?.startsWith(selectedDate.id));
    const withoutSelect = calendarDataAttrs.value?.filter((entry: any) => entry.date && !entry.date?.startsWith(selectedDate.id));
    if (!selectedEntry) {
      return toaster.info("Aucune donnée disponible pour cette date");
    } else {
      const updated = {
        ...selectedEntry,
        ...{ highlight: { color: "blue", fillMode: "outline" } },
      };

      calendarDataAttrs.value = [...withoutSelect, updated];
      emit("day-click", selectedDate);
    }
  }
};

/**
 * Gère l'événement de clic sur un jour du calendrier.
 * @function dayClick
 * @param {any} $event - L'événement de clic.
 * @returns {void}
 */
const dayClick = ($event: any) => {
  if (moment($event.date).isSameOrBefore(datetime.helpers.today)) {
    setSelectedDateOnCalendar($event);
  } else {
    console.warn("Données non disponibles");
  }
};

watchEffect(async () => {
  calendarDataConf.value = calendarData(props.dataType);
});

watch(calendarDataConf, async () => {
  await fetchAllCalendars();
});

watch(
  () => calendars.value,
  (val) => {
    calendarDataAttrs.value = val;
  }
);

onMounted(async () => {
  await fetchAllCalendars();
  calendarStore.formatCalendarData();
});

onUnmounted(() => {
  calendarStore.resetCalendarData();
});
// https://www.netlify.com/blog/2021/01/29/deep-dive-into-the-vue-composition-apis-watch-method/
</script>

<style scoped></style>
