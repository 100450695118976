import * as _ from "lodash";

/**
 * Crée un graphique de type "chart" à partir d'un tableau de données groupées.
 *
 * @param firstCol - Le nom de la première colonne utilisée pour regrouper les données.
 * @param group - Le tableau de données groupées.
 * @returns Un tableau contenant les graphiques créés à partir des données groupées.
 */
export const createChartGraphForBurst = (firstCol: string, group: any[]) => {
  return Object.values(
    group.reduce(function (acc, cur) {
      const isExist = acc[cur[firstCol]];
      if (isExist) {
        acc[cur[firstCol]] = _.merge(isExist, cur);
        return acc;
      } else {
        acc[cur[firstCol]] = cur;
        return acc;
      }
    }, {})
  );
};
