<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input
        type="checkbox"
        :name="fieldObj.key"
        :id="fieldObj.key"
        v-model="fieldObj.value"
        :checked="fieldObj.value"
        :key="fieldObj.id"
        autofocus
        @change="debounce"
        class="focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded"
      />
    </div>
    <div class="ml-3 text-sm">
      <label :for="fieldObj.key" class="font-medium text-gray-700">
        {{ fieldObj.title }}
      </label>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { FieldObject } from "@/index";
import { useDebounceFn } from "@vueuse/core";

const props = defineProps<{
  fieldObj: FieldObject;
}>();

const emit = defineEmits(["edited-input"]);

const debounce = useDebounceFn(() => {
  const valuetoDispatch = {
    ...props.fieldObj,
    value: props.fieldObj.value,
    id: props.fieldObj.id,
  };
  emit("edited-input", valuetoDispatch);
}, 700);
</script>
