import * as _ from "lodash";

import { BurstListItem, LensVariable } from "@/index";

/**
 * Crée une série de données en format liste logarithmique à 3 axes.
 *
 * @param tables - Les tables de données.
 * @param lens_variables - Les variables de lens.
 * @param module - Le module.
 * @returns Une série de données au format liste logarithmique à 3 axes.
 */
export const create3AxesListLogSeries = (tables: any[] = [], lens_variables: LensVariable[], module: any) => {
  let newTable = {
    x: [],
    y: [],
    z: [],
  } as any;

  tables.map((el: BurstListItem) => {
    newTable.x.push([el.x_freq, el.x_ppv]);
    newTable.y.push([el.y_freq, el.y_ppv]);
    newTable.z.push([el.z_freq, el.z_ppv]);
  });
  var COLOR_ALL = ["#338aff", "#f53630", "#49e6ce", "#61f325"];

  const findInModule = (axe: string) => {
    const _ch = module.parameters?.channel_mapping;
    return Object.entries(_ch).find((_v: any[]) => _v[0].toLowerCase().includes(axe));
  };

  const findInVariables = (entry: string) =>
    lens_variables.length > 0 ? lens_variables.find((v) => v.vc_name.toLowerCase().includes(entry.toLowerCase())) : { disp_name: entry };

  const series = Object.entries(newTable).map((el, i) => {
    const entry = findInModule(el[0])!

    return {
      name: entry[0],
      type: "scatter",
      symbolSize: 7,
      data: el[1],
      color: COLOR_ALL[i],
    };
  });

  return _.isEmpty(tables) ? [] : series;
};
