import { ApplicationOption, ApplicationType, ClientApplicationOption, MeasurementPoint } from "@/index.d";

/**
 * update or add measurement point to client application
 *              if remove is true, remove measurement point from client application
 * @param clientApplication ClientApplicationOption
 * @param measurementPoint MeasurementPoint
 * @param type ApplicationType
 * @param remove? boolean
 * @returns ClientApplicationOption | void
 *
 */
export const updateOrAddMeasurementPoint = (clientApplication: ClientApplicationOption, measurementPoint: MeasurementPoint, type: ApplicationType, remove = false) => {
  const hasType = measurementPoint.measurement_type_list[0].toLowerCase().includes(type) ? type : false;
  if (!hasType) {
    return;
  }

  const _appOption = clientApplication.applications?.find((appOption: ApplicationOption) => appOption.type.startsWith(hasType));
  if (!_appOption) {
    return;
  }
  const _otherApps = clientApplication.applications?.filter((appOption: ApplicationOption) => !appOption.type.startsWith(hasType));

  const _measurementPointIndex = _appOption.measurement_point_list.findIndex((mp) => mp.endpoint === measurementPoint.endpoint);
  if (_measurementPointIndex >= 0) {
    if (remove) {
      _appOption.measurement_point_list.splice(_measurementPointIndex, 1);
    } else {
      _appOption.measurement_point_list[_measurementPointIndex] = measurementPoint;
    }
  } else {
    _appOption.measurement_point_list.push(measurementPoint);
  }

  const _apps = [..._otherApps, _appOption];

  return {
    ...clientApplication,
    applications: _apps,
  };
};
